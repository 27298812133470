// constants
import { MESSAGE_TYPES } from 'constants/message'

// utils
import { reportErrors, reportMessage } from 'helpers/log'
import {
  dismissAllToasts,
  makeToast,
} from 'components/common/Toast/helpers/utils'

import type { Entity } from 'types/entity'
import type { ShowToastOptions, ToastType } from 'components/common/Toast/type'

/**
 * Displays a toast notification with the specified type, title, and optional properties.
 * @param type - The type of toast to display.
 * @returns id of the toast created.
 */

export const showToast =
  (type: ToastType) =>
  (title: string, props: ShowToastOptions = {}): string =>
    makeToast({ type, title, ...props })

/**
 * Triggers a toast notification of type 'info'.
 *
 * @param title - The main title for the info toast.
 * @param props - Optional additional properties for the toast.
 * @returns - The ID of the toast generated by the makeToast function.
 * @example showInfo('Hello World', {description: 'This is a toast notification'})
 */
export const showInfo = showToast(MESSAGE_TYPES.info)

/**
 * Triggers a toast notification of type 'warning'.
 *
 * @param title - The main title for the warning toast.
 * @param props - Optional additional properties for the toast.
 * @returns - The ID of the toast generated by the makeToast function.
 * @example showWarn('Hello World', {description: 'This is a toast notification'})
 */
export const showWarn = showToast(MESSAGE_TYPES.warning)

/**
 * Triggers a toast notification of type 'success'.
 *
 * @param title - The main title for the success toast.
 * @param props - Optional additional properties for the toast.
 * @returns - The ID of the toast generated by the makeToast function.
 * @example showSuccess('Hello World', {description: 'This is a toast notification'})
 */
export const showSuccess = showToast(MESSAGE_TYPES.success)

/**
 * Triggers a toast notification of type 'error'.
 *
 * @param title - The main title for the error toast.
 * @param props - Optional additional properties for the toast.
 * @returns - The ID of the toast generated by the makeToast function.
 * @example showError('Hello World', {description: 'This is a toast notification'})
 * Note: The default duration of error toast is longer (100000 ms) than other types of toasts.
 */

export const showError = showToast(MESSAGE_TYPES.error)

type ShowEntityCrudMessage<T> = {
  entity?: string
  status: string
  type?: string
  error?: Error | null
  toastId?: string
  subject?: T
  autoClose?: number
  message?: string
  duration?: number
}

export const showEntityCrudMessage = <T extends Partial<Entity>>({
  type = MESSAGE_TYPES.info,
  entity,
  status,
  error,
  toastId,
  subject,
  message,
  duration = 1000,
}: ShowEntityCrudMessage<T>): string => {
  let id = toastId
  const { name, type: entityType } = (subject || {}) as Entity
  const messagePrefix = `${name || ''} ${entity || entityType} ${status} `
  const successMessage = `${messagePrefix} successfully `
  const errorMessage = `${messagePrefix} failed. ${error?.message}`
  const isError = type === MESSAGE_TYPES.error
  const displayMessage = isError ? errorMessage : message || successMessage
  if (id) {
    makeToast({
      title: displayMessage,
      type: type as ToastType,
      toastOptions: {
        id,
        duration,
      },
    })
  } else {
    id = showInfo(displayMessage, { type: type as ToastType })
  }

  if (error) {
    reportErrors(error)
  }

  return id
}

export const showCrudResponseMessage = <T extends Partial<Entity>>({
  error,
  onlyToastOnErrors = false,
  ...rest
}: ShowEntityCrudMessage<T> & { onlyToastOnErrors?: boolean }): void => {
  const commonProps = {
    ...rest,
    error,
  }
  if (error) {
    showEntityCrudMessage<T>({
      ...commonProps,
      type: MESSAGE_TYPES.error,
    })
    return
  }

  if (!onlyToastOnErrors) {
    showEntityCrudMessage<T>({
      ...commonProps,
      type: MESSAGE_TYPES.success,
    })
  }
}

export const clearAllMessages = (): void => dismissAllToasts()

export const displayAndLogErrorMessage = (newMessage: string): void => {
  showError(newMessage)
  reportMessage(newMessage)
}
