import { ReactElement, useCallback, useMemo } from 'react'
import styled from '@emotion/styled/macro'

// constants
import { ASSET_PROFILE_MEDIA_TYPES } from 'constants/assets'

// components
import { BaseWidgetWithAssetState } from 'components/assets/assetsProfile/widgets/BaseWidget'

import type { AssetWidgetSettings } from 'types/asset'
import type { ThemeType } from 'types/common'

const StyedImgContainer = styled.div<{ gridHeight?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // Set a fixed height when printing
  height: ${({ gridHeight }) => (gridHeight ? `${gridHeight * 20}px` : '100%')};
`

const AssetBrandingImageWidget = ({
  assetId,
  settings,
  theme,
  mediaType,
  onWidgetLoad,
  dataGrid,
}: {
  assetId: string
  settings: AssetWidgetSettings
  theme?: ThemeType
  mediaType?: string
  onWidgetLoad?: (id?: string) => void
  dataGrid?: { h: number; w: number }
}): ReactElement => {
  const isPrintable = useMemo(
    () => mediaType === ASSET_PROFILE_MEDIA_TYPES.PRINTABLE,
    [mediaType]
  )

  const render = useCallback(() => {
    const { imageSrc } = settings

    const { h: gridHeight } = dataGrid || {}

    return imageSrc ? (
      <StyedImgContainer gridHeight={gridHeight}>
        <img
          className='h-100'
          src={imageSrc}
          alt=''
          onLoad={() => onWidgetLoad?.()}
        />
      </StyedImgContainer>
    ) : null
  }, [settings, dataGrid, onWidgetLoad])

  return (
    <BaseWidgetWithAssetState
      assetId={assetId}
      settings={settings}
      render={render}
      className='h-100 w-100 p-2'
      theme={theme}
      forceScroll={isPrintable}
      isPrintable={isPrintable}
    />
  )
}

export default AssetBrandingImageWidget
