import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// constants
import { NO_DATA_PLACEHOLDER } from 'constants/common'
import { WIDGET_TYPES } from 'constants/widget'

// components
import Table from 'components/assets/assetsProfile/widgets/common/Table'
import { NoAvailableWidget } from 'components/widget'

// utils
import { getAllJSONFormProperties } from 'helpers/issue'
import { getCleanPropertyName } from 'helpers/assetProfile'
import { JSON_DATA_TYPES } from 'constants/formBuilder'

import type { JSONFormBody } from 'types/issue'
import { SinglePageTableProps } from '.'

/**
 * Designed to render a table from properties of the "list of objects" type
 * e.g. [{ name: "Top", title: "cat" }, ...]
 */
const SinglePropertyTable = ({
  properties: propsProperties,
  propertiesMetadata,
  visibleProperties,
  tableStyle,
  withTableHover,
  theme,
  jsonFormBody,
}: Pick<
  SinglePageTableProps,
  | 'properties'
  | 'propertiesMetadata'
  | 'visibleProperties'
  | 'timezone'
  | 'tableStyle'
  | 'withTableHover'
  | 'theme'
> & { jsonFormBody?: JSONFormBody }): ReactElement => {
  const properties = useMemo(
    () => ({
      ...propsProperties,
    }),
    [propsProperties]
  )

  // Finding a property to display
  const displayedPropertyMeta = useMemo(
    () =>
      propertiesMetadata.find(
        ({ name, type }) =>
          visibleProperties?.includes(name as string) &&
          type === JSON_DATA_TYPES.array
      ),
    [propertiesMetadata, visibleProperties]
  )

  const columns = useMemo(() => {
    if (!displayedPropertyMeta?.name || !jsonFormBody) return []

    const jsonFormProperties = getAllJSONFormProperties(jsonFormBody)
    const schemaFieldName = getCleanPropertyName(displayedPropertyMeta.name)
    const fieldSchema = jsonFormProperties[schemaFieldName]
    const { uischema } = jsonFormBody
    const fieldUiSchema = _.get(uischema, schemaFieldName)

    // Should be an array of objects
    if (
      fieldSchema?.items?.type !== JSON_DATA_TYPES.object ||
      !_.isObject(_.first(properties[displayedPropertyMeta.name]))
    )
      return []

    const fieldPropertiesSchema = fieldSchema.items.properties

    // Getting an order of columns
    const order: string[] =
      _.get(fieldUiSchema, 'items.ui:order') ||
      Object.keys(fieldPropertiesSchema)

    const columnsWidth = 100 / order.length

    return order.map(key => {
      const propertySchema = fieldPropertiesSchema[key]

      return {
        accessor: key,
        Header: propertySchema?.title,
        width: columnsWidth,
      }
    })
  }, [displayedPropertyMeta, jsonFormBody, properties])

  const data = useMemo(() => {
    const displayedPropertyKey = displayedPropertyMeta?.name || ''
    return properties[displayedPropertyKey] || []
  }, [displayedPropertyMeta, properties])

  return _.isEmpty(data) ? (
    <NoAvailableWidget
      widgetType={WIDGET_TYPES.table}
      content={NO_DATA_PLACEHOLDER}
    />
  ) : (
    <Table
      data={data}
      columns={columns}
      theme={theme}
      tableStyle={tableStyle}
      withTableHover={withTableHover}
      withHeader
    />
  )
}

export default SinglePropertyTable
