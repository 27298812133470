import { useLayoutEffect, useRef, ReactElement, KeyboardEvent } from 'react'
import styled from '@emotion/styled/macro'
import { useGetSet } from 'react-use'

// utils
import { sanitizeString } from 'helpers/utils'

// constants
import { ENTITY_NAME_CHARACTER_LIMIT } from 'constants/common'

import scss from './index.module.scss'

const StyledBorder = styled.div`
  border-bottom-color: ${props => props.theme.primary};
`

// 1. Render "Untitled map", "Untitled layer" or "Untitled chart" as default title and placeholder (src/components/map/MapBlade/index.jsx)
// 2. If the title is "Untitled map" or "Untitled layer" (a new map or a new layer, or user didn't change the title before), pass the empty title '' to this TitleEditor and let the TitleEditor display placeholder for a better user experience. So that if the user wants to edit the name, they don't have to delete the default name and type. (In order to render an empty title, it has to use useRef because it would be an uncontrolled component)
// 3. If user didn't update the title, save the placeholder as title
// 4. If user did update the title, save the user input as title
// 5. onFocus - add active styling
// 6. onBlur - remove active styling and check value (2&3)
// 7. onKeypress (enter) - remove active styling, check value (2&3) and blur the input

const TitleEditor = ({
  title = '',
  placeholder,
  onChange,
  disabled = false,
  limit = ENTITY_NAME_CHARACTER_LIMIT,
}: {
  title?: string
  placeholder?: string
  onChange: (v: string) => void
  disabled?: boolean
  limit?: number
}): ReactElement => {
  const inputGroupRef = useRef<HTMLElement>()
  const inputRef = useRef<HTMLInputElement>()
  const [getEnterHandled, setEnterHandled] = useGetSet(false)

  useLayoutEffect((): void => {
    if (inputRef.current) {
      const { current } = inputRef

      current.value = title

      const onValueChange = () => {
        if (!inputGroupRef.current) return

        inputGroupRef.current.classList.remove(scss.active)
        const newTitle = current?.value || placeholder

        let cleanText = sanitizeString(newTitle) || ''
        if (cleanText?.length > limit) {
          cleanText = cleanText.substring(0, limit)
        }

        if (!disabled) {
          onChange(cleanText)
        }
      }

      const handleFocus = () => {
        if (!inputGroupRef.current) return

        inputGroupRef.current.classList.add(scss.active)
      }

      const handleBlur = () => {
        if (getEnterHandled()) {
          setEnterHandled(false)
        } else {
          onValueChange()
        }
      }

      const handleEnter = (e: KeyboardEvent) => {
        if (!current) return

        if (e.key === 'Enter') {
          onValueChange()
          setEnterHandled(true)
          current.blur()
        }
      }

      current.addEventListener('focus', handleFocus)
      current.addEventListener('blur', handleBlur)
      current.addEventListener('keypress', handleEnter)

      return () => {
        current.removeEventListener('focus', handleFocus)
        current.removeEventListener('blur', handleBlur)
        current.removeEventListener('keypress', handleEnter)
      }
    }

    return undefined

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange])

  return (
    <div ref={inputGroupRef} className={scss.inputGroup}>
      <input
        ref={inputRef}
        className={scss.input}
        placeholder={placeholder || 'title'}
        type='text'
        autoComplete='off'
        disabled={disabled}
        data-testid='title'
        maxLength={limit}
      />
      <StyledBorder className={scss.border} />
    </div>
  )
}

export default TitleEditor
