// libraries
import {
  useCallback,
  useMemo,
  memo,
  PropsWithChildren,
  ReactElement,
} from 'react'
import _ from 'lodash'
import styled from '@emotion/styled/macro'

// constants
import { TIME_RANGE_MODES, PROPERTY_VARIABLE_FORMATS } from 'constants/filter'
import {
  SPEC_PARAMETERS_TYPES,
  SPEC_PARAMETERS_FORMATS,
} from 'constants/common'

// utils
import { isRequiredKey } from 'helpers/unipipe'
import { switchcaseF, sanitizeString, isRangeValid } from 'helpers/utils'
import { useTimezone } from 'hooks'
import { getTimeRangeString } from 'helpers/datetime'
import { reportMessage } from 'helpers/log'
import { getResultForMultiSelect } from 'helpers/issue'
import useImageCarousel, {
  ImageResource,
} from 'components/common/Image/useImageCarousel'

// components
import {
  TitleWithTooltip,
  NumericInput,
  TextInput,
  Toggle,
  MultiSelect,
  DateTimePicker,
  RangeSlider,
} from 'components/common'
import AbsoluteTimePicker from 'components/common/DateTimePicker/AbsoluteTimePicker'

import type {
  SpecificationParameterOption,
  SpecificationParameters,
  SpecificationParameterValue,
  SpecParams,
} from 'types/common'

// scss
import scss from './index.module.scss'

const DatePickerStyled = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
    .react-datepicker__input-container input {
      width: inherit;
      height: 100%;
      border: none;
      outline: none;
      height: 31px;
      font-size: 12px;
      padding-left: 8px;
    }
  }
  .react-datepicker {
    padding: 16px 16px 16px 0;
  }
`

const CalendarContainer = ({
  className,
  children,
}: PropsWithChildren<{ className: string }>) => {
  return (
    <div className={className}>
      <div style={{ position: 'relative' }}>{children}</div>
    </div>
  )
}

export const InputLabel = ({
  title,
  tooltip,
  className,
  description,
}: {
  title: string
  tooltip?: string
  className?: string
  description?: string
}): ReactElement => {
  return (
    <div className='mb-1'>
      <TitleWithTooltip title={title} tooltip={tooltip} className={className} />
      {description && (
        <div className='text-secondary smallText'>
          {sanitizeString(description)}
        </div>
      )}
    </div>
  )
}

const SpecificationParametersList = ({
  // The specifications for rendering backend-oriented filtering controls
  specificationParameters = {},
  // The key-value pairs of the specification parameters
  specParams,
  cachedSpecParams,
  imageResources = [],
  onChange,
  disabled = false,
  keepQuickSelectOpen = false,
  labelClassName,
  rowClassName,
}: {
  specificationParameters?: SpecificationParameters
  cachedSpecParams?: SpecParams | null
  specParams: SpecParams | null
  imageResources?: ImageResource[]
  onChange: (val: SpecParams) => void
  disabled?: boolean
  keepQuickSelectOpen?: boolean
  labelClassName?: string
  rowClassName?: string
}) => {
  const { timezone, timezoneAbbr } = useTimezone()
  const { renderCarousel, renderImages } = useImageCarousel({ imageResources })

  const { startTime, endTime, ...restSpecificationParameters } =
    specificationParameters

  const timeParametersList = useMemo(() => {
    if (!startTime || !endTime) return undefined

    const datetimeRangeKey = getTimeRangeString({
      start: cachedSpecParams?.startTime,
      end: cachedSpecParams?.endTime,
    })

    return (
      <div className={scss.row}>
        <InputLabel
          title={`Date Time Range (${timezoneAbbr})`}
          description={startTime.description || endTime.description}
          className={labelClassName}
        />
        <DateTimePicker
          key={datetimeRangeKey}
          timezone={timezone}
          startTime={{
            value: cachedSpecParams?.startTime,
            mode: TIME_RANGE_MODES.absoluteTime,
          }}
          endTime={{
            value: cachedSpecParams?.endTime,
            mode: TIME_RANGE_MODES.absoluteTime,
          }}
          onChange={onChange}
          keepQuickSelectOpen={keepQuickSelectOpen}
        />
      </div>
    )
  }, [
    cachedSpecParams?.endTime,
    cachedSpecParams?.startTime,
    endTime,
    keepQuickSelectOpen,
    labelClassName,
    onChange,
    startTime,
    timezone,
    timezoneAbbr,
  ])

  const renderSpecParameter = useCallback(
    (propertyName: string, specs: SpecificationParameterValue) => {
      const { type, necessity, format, ...restSpecs } = specs

      const onValueChange = (value: unknown) => {
        onChange({ [propertyName]: value })
      }

      const specParamValue = specParams?.[propertyName]

      const sharedProps = {
        ...restSpecs,
        value: specParamValue,
        onChange: onValueChange,
        className: 'form-control',
        disabled,
      }

      const renderNumber = () => {
        if (isRangeValid(specs?.range)) {
          const props = _.pick(sharedProps, [
            'range',
            'defaultValue',
            'step',
            'value',
            'onChange',
            'disabled',
            'unit',
            'showMarks',
          ])

          return (
            <RangeSlider {...props} noGroupStyle hideInput className='mt-2' />
          )
        }

        return <NumericInput allowEmpty {...sharedProps} />
      }

      const renderString = () => {
        if (_.includes([SPEC_PARAMETERS_FORMATS.dateTime], format)) {
          return (
            <DatePickerStyled>
              <AbsoluteTimePicker
                timezone={timezone}
                onChange={onValueChange}
                selectedTime={specParamValue as string}
                inline={false}
                showTimeSelect
                calendarContainer={CalendarContainer}
                dateFormat='MMMM d, yyyy h:mm aa'
              />
            </DatePickerStyled>
          )
        }

        if (format === PROPERTY_VARIABLE_FORMATS.image) {
          return renderImages(specParamValue as [])
        }

        return <TextInput {...sharedProps} />
      }

      const renderBoolean = () => {
        const checked = Boolean(specParamValue)
        const { displayName, getLabel, ...rest } = sharedProps

        return (
          <Toggle
            {...rest}
            label={getLabel ? getLabel(sharedProps) : displayName}
            checked={checked}
            onToggle={() =>
              onChange({
                [propertyName]: !checked,
              })
            }
          />
        )
      }

      const renderEnum = () => {
        const optionKey = 'key'
        const { isMulti = false, options, separator = ' ' } = specs
        const { creatable = true, value } = sharedProps

        const newValue = getResultForMultiSelect({
          value,
          isMulti,
          separator,
        })

        const additionalOptions =
          isMulti ||
          !creatable ||
          _.isNil(value) ||
          _.find(options, { [optionKey]: value })
            ? []
            : [{ [optionKey]: value }]

        const optionsWithDefaultedDisplayNames = _.map(
          [...options, ...additionalOptions] as SpecificationParameterOption[],
          option => ({
            ...option,
            label: option.displayName || option[optionKey],
            value: option[optionKey],
          })
        )

        return (
          <MultiSelect
            isMulti={isMulti}
            placeholder='Select ...'
            isClearable={!isRequiredKey(necessity)}
            {...sharedProps}
            value={newValue}
            className=''
            creatable={creatable}
            options={optionsWithDefaultedDisplayNames}
            onChange={(optionValue: unknown) => {
              onChange({ [propertyName]: optionValue || '' })
            }}
            useOptionValueOnly
            isDisabled={disabled}
          />
        )
      }
      return switchcaseF({
        [SPEC_PARAMETERS_TYPES.number]: renderNumber,
        [SPEC_PARAMETERS_TYPES.string]: renderString,
        [SPEC_PARAMETERS_TYPES.boolean]: renderBoolean,
        [SPEC_PARAMETERS_TYPES.enum]: renderEnum,
      })(() => {
        reportMessage(
          `The spec parameters type-${type} is not supported yet`,
          'log',
          { specs }
        )
      })(type)
    },
    [disabled, onChange, renderImages, specParams, timezone]
  )

  const nonTimeParametersList = useMemo(
    () =>
      _.compact(
        _.map(restSpecificationParameters, (specs, dKey) => {
          const {
            necessity,
            displayName,
            hint,
            visible = true,
            description,
          } = specs
          if (!visible) return undefined

          const title = `${sanitizeString(displayName) || dKey} ${
            isRequiredKey(necessity) ? '*' : ''
          }`

          const tooltip = sanitizeString(hint)
          return (
            <div key={dKey} className={`${scss.row} ${rowClassName}`}>
              <InputLabel
                title={title}
                tooltip={tooltip}
                className={labelClassName}
                description={description}
              />
              {renderSpecParameter(dKey, specs)}
            </div>
          )
        })
      ),
    [
      labelClassName,
      renderSpecParameter,
      restSpecificationParameters,
      rowClassName,
    ]
  )

  return (
    <>
      <div data-testid='specificationParametersList'>
        {timeParametersList}
        {nonTimeParametersList}
      </div>
      {renderCarousel()}
    </>
  )
}

export default memo(SpecificationParametersList)
