import { useRef, ReactElement } from 'react'
import _ from 'lodash'
import { useSize } from 'ahooks'

// components
import Button, { BUTTON_VARIANTS, BUTTON_SIZES } from 'components/common/Button'
import useImageCarousel from 'components/common/Image/useImageCarousel'
import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'

// utils
import { isJsonFormWidgetHidden } from 'helpers/formBuilder'

// types
import type { WidgetProps } from '@rjsf/utils'

import scss from './index.module.scss'

const ImageWidget = ({
  name,
  schema,
  uiSchema,
  value = [],
  readonly,
  required,
  formContext,
  rawErrors,
}: WidgetProps): ReactElement | null => {
  const { title, maxItems = 1 } = schema || {}
  const { imageResources, isPreview } = formContext || {}
  const { renderCarousel, renderImages } = useImageCarousel({ imageResources })

  const isHidden = isJsonFormWidgetHidden(uiSchema)

  const ref = useRef(null)
  const size = useSize(ref)

  return isHidden ? null : (
    <>
      <div ref={ref}>
        <WidgetWrapper
          name={name}
          label={title}
          required={required}
          childrenContainerClassName={scss.container}
          rawErrors={rawErrors}
          isLarge={isPreview}
          inline
        >
          {isPreview && (
            <div className='ms-3'>
              <Button
                variant={BUTTON_VARIANTS.secondary}
                className={`${scss.btn} me-2`}
                size={BUTTON_SIZES.large}
                iconSize={28}
                icon='FileUploaderIcon'
              />
              <Button
                variant={BUTTON_VARIANTS.secondary}
                size={BUTTON_SIZES.large}
                iconSize={28}
                icon='TakePhotoIcon'
                className={scss.btn}
              />
            </div>
          )}
        </WidgetWrapper>
      </div>

      {!readonly && (
        <div className={scss.photosCount}>
          {value?.length || 0}/{maxItems} photos
        </div>
      )}

      {!_.isEmpty(value) &&
        !_.isEmpty(size) &&
        renderImages(value, size.width / 2 - 20)}
      {renderCarousel()}
    </>
  )
}

export default ImageWidget
