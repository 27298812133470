// libraries
import { ReactElement } from 'react'
import styled from '@emotion/styled/macro'

// constants
import {
  SLIDER_EXTRA_LENGTH_LARGE,
  SLIDER_EXTRA_LENGTH_REGULAR,
} from 'constants/ui'

// components
import { TitleWithTooltip } from 'components/common'
import Range from 'components/common/RangeSlider/Range'
import Slider from 'components/common/RangeSlider/Slider'

// styles
import './index.scss'

import type { RangeSliderProps } from './types'

const getWrapperMargin = ({ isLarge }: { isLarge?: boolean }) =>
  `${isLarge ? SLIDER_EXTRA_LENGTH_LARGE : SLIDER_EXTRA_LENGTH_REGULAR}px`

/** Making a margin for the same amount of px as an added extra slider length */
const SliderWrapper = styled.div<{ isLarge?: boolean }>`
  margin-left: ${getWrapperMargin};
  margin-right: ${getWrapperMargin};
`

const RangeSliderContainer = ({
  title,
  tooltip,
  isSlider,
  rangeValue,
  children,
  noGroupStyle,
  hideInput,
  ...rest
}: RangeSliderProps): ReactElement => {
  const renderRangeSlider = () => (
    <SliderWrapper isLarge={rest.isLarge}>
      {isSlider ? (
        <Slider {...rest} hideInput={hideInput} />
      ) : (
        <Range {...rest} rangeValue={rangeValue || []} hideInput={hideInput} />
      )}
      {children}
    </SliderWrapper>
  )

  return noGroupStyle ? (
    renderRangeSlider()
  ) : (
    <div className='groupOption'>
      <div className='groupOptionTitle d-flex justify-content-between'>
        <TitleWithTooltip title={title || ''} tooltip={tooltip} />
        {isSlider ? null : rangeValue?.join(' - ')}
      </div>
      <div className='groupOptionContent'>{renderRangeSlider()}</div>
    </div>
  )
}

RangeSliderContainer.defaultProps = {
  title: '',
  tooltip: '',
  isSlider: true,
  noGroupStyle: false,
  disabled: false,
  hideInput: false,
  makeUnitPlural: true,
  showDots: false,
}

export default RangeSliderContainer
