import { ReactElement, PropsWithChildren, useCallback } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled/macro'
import { Theme, css } from '@emotion/react'
import { FieldProps } from '@rjsf/utils'

import Button, { BUTTON_SIZES, BUTTON_VARIANTS } from 'components/common/Button'

const getBorderColor = ({
  isFocused,
  hasError,
  theme,
}: {
  isFocused?: boolean
  hasError?: boolean
  theme: Theme
}) => {
  switch (true) {
    case hasError:
      return theme.danger
    case isFocused:
      return theme.primary
    default:
      // Same as input's (comes from the 'form-control')
      return '#dddddd'
  }
}

const StepperButton = styled(Button)<{
  isFocused?: boolean
  hasError?: boolean
}>`
  background-color: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.primary};
  border-color: ${getBorderColor};

  &:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
  &:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
`

const Container = styled.div<{ inline?: boolean }>`
  display: flex;
  align-items: stretch;

  ${({ inline }) =>
    inline
      ? css`
          margin-left: 16px;
        `
      : ''};
`

const StepperButtons = ({
  formData,
  onChange,
  disabled,
  inline,
  isFocused,
  hasError,
  isLarge,
  min,
  max,
  children,
}: PropsWithChildren<
  Pick<FieldProps, 'formData' | 'onChange' | 'disabled'> & {
    inline?: boolean
    isFocused?: boolean
    hasError?: boolean
    isLarge?: boolean
    min?: number
    max?: number
  }
>): ReactElement => {
  const buttonSize = isLarge ? BUTTON_SIZES.default : BUTTON_SIZES.small

  const onButtonClick = useCallback(
    ({ isIncrement }: { isIncrement: boolean }) => {
      // If there is no current input value,
      // send 0 by default or start from the 'min' value
      if (!_.isNumber(formData)) {
        onChange(_.isNumber(min) ? min : 0)
        return
      }
      // Default to 0
      const currVal = formData || 0
      if (isIncrement) {
        // Do nothing if the number is reached the min/max value
        if (_.isNumber(max) && currVal >= max) return
        onChange(currVal + 1)
      } else {
        if (_.isNumber(min) && currVal <= min) return
        onChange(currVal - 1)
      }
    },
    [min, max, formData, onChange]
  )

  return (
    <Container inline={inline}>
      <StepperButton
        variant={BUTTON_VARIANTS.secondary}
        size={buttonSize}
        icon='AiOutlineMinus'
        onClick={() => onButtonClick({ isIncrement: false })}
        isFocused={isFocused}
        hasError={hasError}
        disabled={disabled}
      />
      {children}
      <StepperButton
        variant={BUTTON_VARIANTS.secondary}
        size={buttonSize}
        icon='AiOutlinePlus'
        onClick={() => onButtonClick({ isIncrement: true })}
        isFocused={isFocused}
        hasError={hasError}
        disabled={disabled}
      />
    </Container>
  )
}

export default StepperButtons
