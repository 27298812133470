// libraries
import { useState, ReactElement, useEffect } from 'react'
import { Field, useField } from 'react-final-form'
import zxcvbn from 'zxcvbn'
import _ from 'lodash'
import { useToggle } from 'react-use'
import styled from '@emotion/styled/macro'

// constants
import { NEW_PASSWORD_MIN_LENGTH } from 'constants/user'

// components
import { Input } from 'components/common/Form'
import { IconButton } from 'components/common'

// utils
import {
  getConnectedStringFromArray,
  switchcase,
  shouldSanitizeStr,
} from 'helpers/utils'

import scss from './index.module.scss'

const isPasswordTooShort = (password: string, minLength: number) =>
  password.length < minLength

const getStrengthMeterClass = (score: number) => {
  return switchcase({
    0: 'bg-danger',
    1: 'bg-danger',
    2: 'bg-warning',
    3: 'bg-success',
    4: 'bg-info',
  })()(score)
}

const StyledMeter = styled.div`
  .bg-danger {
    background: radial-gradient(
      98.95% 125.78% at 50.12% 50%,
      #ff3939 5.21%,
      #d80101 83.33%
    );
  }
  .bg-warning {
    background: radial-gradient(50% 50% at 50% 50%, #ffd15b 0%, #ffbc11 100%);
  }
  .bg-success {
    background: radial-gradient(50% 50% at 50% 50%, #00ec42 0%, #01d83d 100%);
  }
  .bg-info {
    background: radial-gradient(50% 50% at 50% 50%, #37cfff 0%, #00c1ff 100%);
  }
`

const validate = (value: string): string | undefined =>
  shouldSanitizeStr(value) ? 'Enter a valid password' : undefined

const PasswordInputField = ({
  name = 'password',
  id,
  autoComplete,
  needsValidation = false,
}: {
  name?: string
  id?: string
  autoComplete?: string
  needsValidation?: boolean
}): ReactElement => {
  const [passwordShown, togglePassword] = useToggle(false)
  const [passwordResult, setPasswordResult] = useState<{
    score?: number
    feedback?: {
      suggestions: string[]
      warning: string
    }
  }>({})

  const { score = 0, feedback: { suggestions, warning } = {} } = passwordResult
  const field = useField(name).input.value

  const strongPassword = () => (score > 2 ? '' : undefined)

  useEffect(() => {
    if (!needsValidation) return

    const isShortPassword = isPasswordTooShort(field, NEW_PASSWORD_MIN_LENGTH)
    if (!isShortPassword) {
      setPasswordResult(zxcvbn(field))
    } else {
      setPasswordResult({
        score: 0,
        feedback: {
          suggestions: [
            `Enter a memorable phrase (few words) that is ${NEW_PASSWORD_MIN_LENGTH} or more characters long.`,
          ],
        },
      })
    }
  }, [field, needsValidation])

  return (
    <>
      <Field
        {...(needsValidation && { validate: strongPassword })}
        id={id}
        labelClassName='form-label'
        component={Input}
        name={name}
        type={passwordShown ? 'text' : 'password'}
        label='Password'
        placeholder='Enter your password'
        required
        data-testid='password'
        autoComplete={autoComplete}
        validate={validate}
        addon={
          <IconButton
            className={scss.passwordToggle}
            icon={passwordShown ? 'HideIcon' : 'EyeIcon'}
            onClick={togglePassword}
            label={passwordShown ? 'Hide password' : 'Show password'}
            width={16}
          />
        }
      />
      {needsValidation && (
        <>
          <StyledMeter
            className={`progress ${scss.meter}`}
            style={{ backgroundColor: 'transparent' }}
          >
            {_.times(score, strength => {
              return (
                <div
                  key={strength}
                  className={`progress-bar ${scss.bar} ${getStrengthMeterClass(
                    score
                  )}`}
                  style={{ width: '25%' }}
                />
              )
            })}
            {_.times(4 - score, strength => {
              return (
                <div
                  key={strength}
                  className={`progress-bar ${scss.bar}`}
                  style={{ width: '25%', backgroundColor: '#e1e1e1' }}
                />
              )
            })}
          </StyledMeter>
          {(!_.isEmpty(suggestions) || warning) && (
            <div className={`${scss.suggestion} text-secondary`}>
              {warning || getConnectedStringFromArray(suggestions)}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default PasswordInputField
