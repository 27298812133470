// libraries
import { CSSProperties, ReactElement } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled/macro'
import { useMount } from 'react-use'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { DateTimeComparisonEditorLastUnit } from 'types/graphql'

// components
import { StyledField } from 'components/common/Form'
import { Dropdown, IconButton, NumericInput } from 'components/common'

// types
import type { Options } from 'types/common'

const DEFAULT_RELATIVE_TIME = {
  time: 24,
  unit: DateTimeComparisonEditorLastUnit.Hour,
}

const StyledText = styled.span`
  color: ${props => props.theme.primary};
`

export type RelativeTimeValue = { time: number; unit: string }

const RelativeTimeField = ({
  input,
  label,
  required = false,
  description,
  groupOptionStyle = false,
  style,
  labelClassName = 'form-label',
  isDisabled,
  options = [],
  defaultTime,
  inputAddon,
  min,
  max,
}: {
  input: {
    name: string
    value: RelativeTimeValue
    onChange: (v: RelativeTimeValue) => void
    onBlur: (v: RelativeTimeValue) => void
    onFocus: (v: RelativeTimeValue) => void
  }
  defaultTime: RelativeTimeValue
  label: string
  required: boolean
  description: string
  groupOptionStyle: boolean
  labelClassName: string
  style: CSSProperties
  isDisabled: boolean
  options?: Options
  inputAddon?: () => JSX.Element
  min?: number
  max?: number
}): ReactElement => {
  const { value: inputValue, onChange, name } = input

  const { time, unit } = inputValue

  useMount(() => {
    _.delay(() => {
      onChange({
        time: time || defaultTime?.time || DEFAULT_RELATIVE_TIME.time,
        unit: unit || defaultTime?.unit || DEFAULT_RELATIVE_TIME.unit,
      })
    }, 1)
  })

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      description={description}
      style={style}
      labelClassName={labelClassName}
    >
      <div className='d-flex align-items-center'>
        <div className='col-6 position-relative'>
          <NumericInput
            value={time}
            disabled={isDisabled}
            onChange={(newValue: number) => onChange({ time: newValue, unit })}
            className='form-control'
            step={1}
            min={min || 1}
            {...(max && { max })}
          />
          {inputAddon}
        </div>
        <Dropdown
          containerClassName='ms-2'
          placement={TOOLTIP_PLACEMENT.bottom}
          toggleComponent={() => (
            <div className='smallText defaultText cursor-pointer'>
              <StyledText>{unit}</StyledText>
              {options?.length > 1 && <IconButton icon='GoTriangleDown' />}
            </div>
          )}
          {...(options?.length > 1 && {
            onChange: (newUnit: string) => {
              onChange({ time, unit: newUnit })
            },
          })}
          options={options}
          isDisabled={isDisabled || options?.length === 1}
        />
      </div>
    </StyledField>
  )
}

export default RelativeTimeField
