import { createRoot } from 'react-dom/client'
import { useRef, useMemo, useState, ReactElement, CSSProperties } from 'react'
import { useEffectOnce, useUpdateEffect } from 'react-use'
import {
  TabulatorFull as Tabulator,
  Tabulator as TabulatorTypes,
} from 'tabulator-tables'
import _ from 'lodash'
import styled from '@emotion/styled/macro'

// components
import { Button, Tooltip, ExternalLink } from 'components/common'
import { PROPERTY_VARIABLE_FORMATS } from 'constants/filter'

// utils
import { displayTime, switchcase, sortKeysByDate } from 'helpers/utils'
import { useTimezone } from 'hooks'
import { isPropertyTimeFormat, isTimePropertyOption } from 'helpers/filter'

// constants
import {
  FILE_TYPES,
  FILE_TYPE_ICON_MAPPINGS,
  PLACEMENT,
} from 'constants/common'
import { THEMES } from 'constants/colour'
import {
  BUTTON_VARIANTS,
  BUTTON_ICON_POSITIONS,
} from 'components/common/Button'

// types
import type { Payload, PropertiesMetadata, ThemeType } from 'types/common'

import 'tabulator-tables/dist/css/tabulator_materialize.css'
import { Timezone } from 'types/datetime'
import scss from './index.module.scss'

const Container = styled.div`
  color: ${props => (props.theme === THEMES.dark ? '#fff' : '#000')};
  background-color: ${props =>
    props.theme === THEMES.dark ? '#051b32' : '#ffff'};

  .tabulator-col {
    color: ${props => (props.theme === THEMES.dark ? '#fff' : '#000')};
    background-color: ${props =>
      props.theme === THEMES.dark ? '#051b32' : 'transparent'} !important;
  }

  .tabulator-row {
    color: ${props => (props.theme === THEMES.dark ? '#fff' : '#000')};
    background-color: ${props =>
      props.theme === THEMES.dark ? '#051b32' : '#ffff'};
  }

  .tabulator-row.tabulator-row-even {
    background-color: ${props =>
      props.theme === THEMES.dark ? '#051b32' : '#ffff'};
  }

  .tabulator-selectable:hover {
    background-color: ${props =>
      props.theme === THEMES.dark ? '#666' : '#e9ecef'};
    color:${props => (props.theme === THEMES.dark ? '#fff' : '#495057')};
  }

  .tabulator-header {
    background-color: ${props =>
      props.theme === THEMES.dark ? 'transparent' : '#f8f9fa'};
  }

}
`

// * tabulator download options http://tabulator.info/docs/
const TableDownloadButton = ({
  tableInstance,
  fileName = 'explorer-data',
  fileType = FILE_TYPES.csv,
  icon = 'FaFileCsv',
  downloadConfigs,
}: {
  tableInstance: Tabulator
  fileName: string
  colour: string
  icon: string
  fileType: keyof typeof FILE_TYPES
  downloadConfigs: unknown
}): ReactElement => (
  <Tooltip
    placement={PLACEMENT.top}
    trigger={['hover']}
    overlay={`Download as ${fileType}`}
  >
    <Button
      onClick={() => {
        tableInstance.download(
          fileType,
          `${fileName}.${fileType}`,
          downloadConfigs
        )
      }}
      className={scss.downloadButton}
      variant={BUTTON_VARIANTS.secondary}
      icon={icon}
      iconPosition={BUTTON_ICON_POSITIONS.left}
    >
      DOWNLOAD {fileType}
    </Button>
  </Tooltip>
)

const getCellFormatter = (format: string, timezone: Timezone): Payload => {
  if (isPropertyTimeFormat(format)) {
    return {
      formatter: cell =>
        cell?.getValue()
          ? displayTime({
              datetime: cell?.getValue(),
              timezone,
            })
          : '',
    }
  }
  return switchcase({
    [PROPERTY_VARIABLE_FORMATS.url]: {
      formatter: (
        cell: TabulatorTypes.CellComponent,
        formatterParams: TabulatorTypes.FormatterParams,
        onRendered: TabulatorTypes.EmptyCallback
      ) => {
        onRendered(() => {
          const container = createRoot(cell.getElement())
          container.render(<ExternalLink link={cell.getValue()} />)
        })
      },
    },
  })({})(format)
}

const getCellSorter = (format: string): Payload => {
  return switchcase({
    [PROPERTY_VARIABLE_FORMATS.time]: {
      sorter: (a, b) => {
        return sortKeysByDate(a, b)
      },
    },
  })({})(format)
}

const Table = ({
  id,
  data,
  metadata,
  title,
  options,
  style,
  className,
  onRowClick,
  theme = THEMES.dark,
}: {
  id?: string
  title?: string
  data: []

  metadata?: PropertiesMetadata
  options?: {
    downloadFileTypes?: string[]
    downloadConfigs?: unknown
  } & TabulatorTypes.Options
  style?: CSSProperties
  className?: string
  onRowClick: (e: PointerEvent, row: TabulatorTypes.RowComponent) => void
  theme: ThemeType
}): ReactElement => {
  const ref = useRef()
  const [tableInstance, setTableInstance] = useState<Tabulator>()
  const { timezone } = useTimezone()

  const { downloadFileTypes = [FILE_TYPES.csv], downloadConfigs } =
    options || {}

  const timeProperty = useMemo(
    () => _.find(metadata, isTimePropertyOption),
    [metadata]
  )

  const columnsProps = useMemo(() => {
    if (!_.isEmpty(metadata)) {
      return {
        columns: _.map(metadata, ({ value, label, format }) => {
          return {
            ...getCellFormatter(format, timezone),
            ...getCellSorter(format),
            title: label || value,
            field: value,
            width: '250',
          }
        }),
      }
    }

    return { autoColumns: true }
  }, [metadata, timezone])

  useEffectOnce(() => {
    if (ref.current) {
      const tabulatorOptions = {
        movableColumns: true,
        layout: 'fitDataFill',
        ...(timeProperty?.key && {
          initialSort: [{ column: timeProperty?.key, dir: 'desc' }],
        }),
        ...columnsProps,
        ..._.omit(options, ['downloadFileTypes', 'downloadConfigs']),
      } as TabulatorTypes.Options

      const tabulator = new Tabulator(ref.current, tabulatorOptions)
      tabulator.on('tableBuilt', () => {
        tabulator.setData(data)
      })

      tabulator.on('rowClick', onRowClick)
      setTableInstance(tabulator)
    }

    return () => {
      if (tableInstance) {
        tableInstance.destroy()
      }
    }
  })

  useUpdateEffect(() => {
    if (tableInstance) {
      tableInstance.setData(data)
    }
  }, [data])

  const memoizedDownloadIcons = useMemo(
    () =>
      _.isEmpty(downloadFileTypes) ? (
        <></>
      ) : (
        tableInstance &&
        _.map(downloadFileTypes, fileType => (
          <TableDownloadButton
            key={fileType}
            tableInstance={tableInstance}
            fileName={`${title}-data`}
            fileType={fileType}
            icon={FILE_TYPE_ICON_MAPPINGS[fileType]}
            downloadConfigs={downloadConfigs}
          />
        ))
      ),
    [tableInstance, downloadFileTypes, title, downloadConfigs]
  )

  return (
    <div style={style}>
      {memoizedDownloadIcons}
      <Container id={id} ref={ref} className={className} theme={theme} />
    </div>
  )
}

export default Table
