import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useMemo,
  useCallback,
} from 'react'
import styled from '@emotion/styled/macro'
import _ from 'lodash'
import scss from './index.module.scss'

const ColoredCircle = styled.div<{ color: string; isFaded?: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  opacity: ${({ isFaded }) => (isFaded ? 0.6 : 1)};
`

export type MapLegendProps = {
  legendConfigs: {
    key: string
    colour: [number, number, number]
    label: string
  }[]
  activeLegendItems: string[]
  setActiveLegendItems: Dispatch<SetStateAction<string[]>>
}

const MapLegend = ({
  legendConfigs,
  activeLegendItems,
  setActiveLegendItems,
}: MapLegendProps): ReactElement => {
  const keyByLegendItem = useMemo(
    () => _.keyBy(legendConfigs, 'key'),
    [legendConfigs]
  )

  const handleCheckboxClick = useCallback(
    (legendItemValue: string) => {
      setActiveLegendItems(prevVisibleLegends =>
        prevVisibleLegends.includes(legendItemValue)
          ? prevVisibleLegends.filter(
              visibleLegendItem => visibleLegendItem !== legendItemValue
            )
          : [...prevVisibleLegends, legendItemValue]
      )
    },
    [setActiveLegendItems]
  )

  return (
    <div className={scss.container}>
      <div className={scss.title}>Legend</div>
      <div>
        {_.map(legendConfigs, ({ key: legendItemValue }) => {
          const { colour, label } = keyByLegendItem[legendItemValue]
          const colorStr = `rgb(${colour.join(',')})`
          const isVisible = activeLegendItems.includes(legendItemValue)

          return (
            <label
              className={scss.legendItem}
              key={legendItemValue}
              htmlFor={legendItemValue}
            >
              <input
                className={scss.legendItemCheckbox}
                type='checkbox'
                name={legendItemValue}
                id={legendItemValue}
                checked={isVisible}
                onChange={() => handleCheckboxClick(legendItemValue)}
              />

              <ColoredCircle color={colorStr} isFaded={!isVisible} />

              <span
                className={`${scss.legendItemText} ${
                  isVisible ? '' : scss.faded
                }`}
              >
                {label}
              </span>
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default MapLegend
