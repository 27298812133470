// libraries
import { ReactElement } from 'react'
import { ColumnBodyOptions } from 'primereact/column'
import _ from 'lodash'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { DATE_FORMAT, MONTH_DAY_AM_PM, UTC } from 'constants/datetime'
import { NO_VALUE_PLACEHOLDER } from 'app/MissionControlMethaneSolution/constants/common'

// utils
import { useTimezone } from 'hooks'
import { displayTime } from 'helpers/utils'

// components
import { TruncatingTextWithTooltip, Tooltip } from 'components/common'

interface DateTimeTemplateProps {
  rowData: unknown
  options: ColumnBodyOptions
  display?: {
    timeFormat?: string
    displayZoneAbbr?: boolean
    shouldUseUserTimeZone?: boolean
  }
  tooltip?: {
    timeFormat?: string
    displayZoneAbbr?: boolean
    shouldUseUserTimeZone?: boolean
  }
}

const DateTimeFormattedTemplate = ({
  rowData,
  options,
  display = {},
  tooltip = {},
}: DateTimeTemplateProps): ReactElement | string => {
  const { timezone } = useTimezone()

  const datetime = _.get(rowData, options.field)

  if (!datetime) {
    return NO_VALUE_PLACEHOLDER
  }

  const {
    timeFormat: displayTimeFormat,
    displayZoneAbbr: displayDisplayZoneAbbr = true,
    shouldUseUserTimeZone: displayUserTimeZone = false,
  } = display

  const {
    timeFormat: tooltipTimeFormat,
    displayZoneAbbr: tooltipDisplayZoneAbbr = true,
    shouldUseUserTimeZone: tooltipUserTimeZone = false,
  } = tooltip

  const displayTimezone = displayUserTimeZone ? timezone : UTC

  const tooltipTimezone = tooltipUserTimeZone ? timezone : UTC

  const formattedTime = displayTime({
    datetime,
    timeFormat: displayTimeFormat,
    timezone: displayTimezone,
    displayZoneAbbr: displayDisplayZoneAbbr,
  })

  const tooltipContent = displayTime({
    datetime,
    timeFormat: tooltipTimeFormat ?? displayTimeFormat,
    timezone: tooltipTimezone,
    displayZoneAbbr: tooltipDisplayZoneAbbr,
  })

  if (tooltipContent === formattedTime) {
    return (
      <TruncatingTextWithTooltip
        className='text-capitalize'
        placement={TOOLTIP_PLACEMENT.top}
        title={tooltipContent}
      >
        <span>{formattedTime}</span>
      </TruncatingTextWithTooltip>
    )
  }

  return (
    <Tooltip
      placement={TOOLTIP_PLACEMENT.top}
      trigger={['hover']}
      overlay={<span>{tooltipContent}</span>}
    >
      <span>{formattedTime}</span>
    </Tooltip>
  )
}

const FormattedDateTimeTemplate =
  ({
    displayOptions = {},
    tooltipOptions = {},
  }: {
    displayOptions?: {
      timeFormat?: string
      displayZoneAbbr?: boolean
      shouldUseUserTimeZone?: boolean
    }
    tooltipOptions?: {
      timeFormat?: string
      displayZoneAbbr?: boolean
      shouldUseUserTimeZone?: boolean
    }
  }) =>
  (rowData: unknown, options: ColumnBodyOptions): ReactElement =>
    (
      <DateTimeFormattedTemplate
        rowData={rowData}
        options={options}
        display={displayOptions}
        tooltip={tooltipOptions}
      />
    )

export const DateTimeTemplate = FormattedDateTimeTemplate({
  displayOptions: {
    shouldUseUserTimeZone: true,
  },
  tooltipOptions: {
    shouldUseUserTimeZone: true,
  },
})

export const DateTimeMonthDayTemplate = FormattedDateTimeTemplate({
  displayOptions: {
    timeFormat: MONTH_DAY_AM_PM,
  },
  tooltipOptions: {
    timeFormat: MONTH_DAY_AM_PM,
  },
})

export const DateTemplate = FormattedDateTimeTemplate({
  displayOptions: {
    timeFormat: DATE_FORMAT,
  },
  tooltipOptions: {
    timeFormat: DATE_FORMAT,
  },
})

export const CustomDateTimeTemplate = ({
  displayOptions,
  tooltipOptions,
}: {
  displayOptions?: {
    timeFormat?: string
    displayZoneAbbr?: boolean
    shouldUseUserTimeZone?: boolean
  }
  tooltipOptions?: {
    timeFormat?: string
    displayZoneAbbr?: boolean
    shouldUseUserTimeZone?: boolean
  }
}) => FormattedDateTimeTemplate({ displayOptions, tooltipOptions })

export default DateTimeFormattedTemplate
