import { ReactElement } from 'react'
import styled from '@emotion/styled/macro'

import { switchcase } from 'helpers/utils'

import * as Icons from 'components/icons'

import {
  ButtonSize,
  BUTTON_SIZES,
  BUTTON_ICON_SIZES,
} from 'components/common/Button'

const getSeparatorHeight = (size?: ButtonSize) =>
  switchcase({
    [BUTTON_SIZES.small]: '13px',
    [BUTTON_SIZES.default]: '16px',
    [BUTTON_SIZES.large]: '19px',
  })('16px')(size)

const StyledHr = styled.span<{ btnSize?: ButtonSize }>`
  width: 1px;
  min-height: ${({ btnSize }) => getSeparatorHeight(btnSize)};
  border-radius: 4px;
  display: block;
  background-color: currentColor;
  opacity: 0.5;
`

const ButtonCheckmark = ({
  buttonSize,
}: {
  buttonSize?: ButtonSize
}): ReactElement => {
  const checkmarkSize = buttonSize
    ? BUTTON_ICON_SIZES[buttonSize] + 1
    : BUTTON_ICON_SIZES.default + 1

  return (
    <div className='d-flex flex-row align-items-center'>
      <Icons.MdCheck className='me-2' size={checkmarkSize} />
      <StyledHr className='me-2' btnSize={buttonSize} />
    </div>
  )
}

export default ButtonCheckmark
