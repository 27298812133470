import {
  useMemo,
  useState,
  useCallback,
  useEffect,
  ReactElement,
  Dispatch,
} from 'react'
import _ from 'lodash'
import { useRecoilValue } from 'recoil'

// utils
import {
  getFlattenedObject,
  getPropertiesMetadataKeyByName,
  isInvalidValue,
} from 'helpers/utils'
import {
  getPropertyDisplayNameFromMetadata,
  getFieldValue,
} from 'helpers/asset'
import { issueTaskDataCollectionFormDefinitionState } from 'recoilStore/issuesStore'
import {
  getCleanPropertyName,
  getJsonFormIdFromAssetProfileId,
} from 'helpers/assetProfile'
import { getAllJSONFormProperties } from 'helpers/issue'
import { isRepeaterWidgetField } from 'helpers/formResponse'

// constants
import { WIDGET_TYPES } from 'constants/widget'
import { THEMES } from 'constants/colour'
import { PROPERTY_TABLE_COLUMNS } from 'constants/assets'
import { NO_DATA_PLACEHOLDER } from 'constants/common'

// components
import { IconButton } from 'components/common'
import { PropertyValueDisplay } from 'components/map/controls/MapPopup/PopupItem'
import Table from 'components/assets/assetsProfile/widgets/common/Table'
import NoAvailableWidget from 'components/widget/common/NoAvailableWidget'
import {
  StyledTitle,
  StyledSection,
} from 'components/assets/assetsProfile/widgets/common/style'

// types
import type { PropertiesMetadata } from 'types/common'
import type {
  Asset,
  AssetProperties,
  AssetRelationshipMany,
  AssetRelationshipOne,
  AssetTypeOptions,
  AssetTableHeader,
} from 'types/asset'
import { JSONFormBody } from 'types/issue'

import PropertiesTable from './PropertiesTable'
import SinglePropertyTable from './SinglePropertyTable'

const getAssetProfilePropertiesList = (properties: AssetProperties) =>
  _.map(properties, (value, key) => {
    return { key, value }
  })

export type SinglePageTableProps = {
  properties: AssetProperties
  propertiesMetadata: PropertiesMetadata
  assetProfileId?: string
  singlePropertyModeEnabled?: boolean
} & AssetTypeOptions

export const getValidProperties = ({
  visibleProperties,
  properties,
  hideInvalidValues,
}: Pick<
  SinglePageTableProps,
  'visibleProperties' | 'properties' | 'hideInvalidValues'
>): { value: unknown; key: string }[] => {
  const filteredProperties = _.isUndefined(visibleProperties)
    ? getAssetProfilePropertiesList(properties)
    : _.flatMap(visibleProperties, property => {
        const value = properties[property] || ''
        if (_.isObject(value) && !_.isArray(value)) {
          return getAssetProfilePropertiesList(getFlattenedObject(value))
        }
        return { key: property, value }
      })

  return hideInvalidValues
    ? _.reject(filteredProperties, ({ value }) => isInvalidValue(value))
    : filteredProperties
}

export const renderPropertiesItem = ({
  properties,
  timezone,
  propertiesMetadata,
  visibleProperties,
  keepPropertyKey = false,
  hideInvalidValues = false,
  jsonFormBody,
  theme,
}: SinglePageTableProps & { jsonFormBody?: JSONFormBody }): {
  propertyKey: string
  propertyValue: ReactElement
}[] => {
  const jsonFormProperties = jsonFormBody
    ? getAllJSONFormProperties(jsonFormBody)
    : {}

  const validProperties = getValidProperties({
    visibleProperties,
    properties,
    hideInvalidValues,
  })

  const propertiesMetadataKeyByName =
    getPropertiesMetadataKeyByName(propertiesMetadata)

  return _.map(validProperties, ({ key, value }) => {
    const {
      format,
      displayName,
      type,
      terms = {},
    } = propertiesMetadataKeyByName[key] || {}

    const schemaFieldName = getCleanPropertyName(key)
    const fieldSchema = jsonFormProperties[schemaFieldName]

    return {
      propertyKey: keepPropertyKey ? key : displayName || _.startCase(key),
      propertyValue: isRepeaterWidgetField(fieldSchema) ? (
        <SinglePropertyTable
          properties={properties}
          propertiesMetadata={propertiesMetadata}
          visibleProperties={[key]}
          tableStyle='bordered'
          withTableHover={false}
          theme={theme}
          jsonFormBody={jsonFormBody}
        />
      ) : (
        <PropertyValueDisplay
          value={getFieldValue({
            termsDisplayName: terms[value]?.displayName,
            value,
            fieldSchema,
          })}
          timezone={timezone}
          format={format}
          type={type}
        />
      ),
    }
  })
}

const SinglePageTable = ({
  properties = {},
  propertiesMetadata,
  visibleProperties,
  theme = THEMES.dark,
  timezone,
  hideInvalidValues = false,
  tableStyle = 'striped',
  assetProfileId,
  singlePropertyModeEnabled,
}: SinglePageTableProps): ReactElement => {
  const issueTaskDataCollectionFormsKeyByFormReference = useRecoilValue(
    issueTaskDataCollectionFormDefinitionState
  )

  const jsonFormBody = useMemo(() => {
    const jsonFormId = getJsonFormIdFromAssetProfileId(assetProfileId)
    return issueTaskDataCollectionFormsKeyByFormReference[jsonFormId]
      ?.jsonFormBody
  }, [assetProfileId, issueTaskDataCollectionFormsKeyByFormReference])

  const sharedProps = {
    properties,
    propertiesMetadata,
    visibleProperties,
    timezone,
    jsonFormBody,
    tableStyle,
    theme,
  }

  return singlePropertyModeEnabled ? (
    <SinglePropertyTable {...sharedProps} />
  ) : (
    <PropertiesTable {...sharedProps} hideInvalidValues={hideInvalidValues} />
  )
}

type AssetSinglePageTableProps = AssetTypeOptions & {
  value: Asset
  properties?: string[]
}

export const AssetSinglePageTable = ({
  value,
  properties: visibleProperties = undefined,
  ...rest
}: AssetSinglePageTableProps): ReactElement => {
  const { properties } = value || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  return (
    <SinglePageTable
      {...rest}
      properties={properties}
      propertiesMetadata={propertiesMetadata}
      visibleProperties={visibleProperties}
    />
  )
}

export type RelatedAssetSinglePageTableProps = AssetTypeOptions & {
  value: Asset & {
    related: AssetRelationshipOne
  }
  properties?: string[]
}

export const RelatedAssetSinglePageTable = ({
  value,
  properties: visibleProperties = undefined,
  ...rest
}: RelatedAssetSinglePageTableProps): ReactElement => {
  const { properties } = value?.related?.asset || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  return (
    <SinglePageTable
      {...rest}
      properties={properties}
      propertiesMetadata={propertiesMetadata}
      visibleProperties={visibleProperties}
    />
  )
}

export type RelatedMultiPagesTableProps = AssetTypeOptions & {
  value: Asset & {
    related: AssetRelationshipMany
  }
  properties?: string[]
  relatedAssetRelationshipId: string
  summaryProperties: string[]
  setHeader: Dispatch<AssetTableHeader>
}

const getSortAssets = ({
  enableSort,
  sortByProperty,
  isAscendingOrder,
  assets,
}: {
  enableSort?: boolean
  isAscendingOrder?: boolean
  sortByProperty: string | undefined
  assets: Asset[]
}): Asset[] => {
  return enableSort
    ? _.orderBy(
        assets,
        [`properties.${sortByProperty}`],
        [isAscendingOrder ? 'asc' : 'desc']
      )
    : assets
}

export const RelatedMultiPagesTable = ({
  value,
  relatedAssetRelationshipId,
  summaryProperties,
  timezone,
  properties: visibleProperties = undefined,
  theme = THEMES.dark,
  hideInvalidValues = false,
  setHeader,
  enableSort = false,
  isAscendingOrder = true,
  sortByProperty,
  tableStyle = 'striped',
}: RelatedMultiPagesTableProps): ReactElement => {
  const { assets = [] } = value?.related || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  const [currentAsset, setCurrentAsset] = useState<Asset>()

  const propertiesMetadataKeyByName = useMemo(
    () => getPropertiesMetadataKeyByName(propertiesMetadata),
    [propertiesMetadata]
  )

  const getSummaryData = useCallback(() => {
    const sortsAssets = getSortAssets({
      enableSort,
      assets,
      sortByProperty,
      isAscendingOrder,
    })

    return _.map(sortsAssets, asset => {
      const result = renderPropertiesItem({
        properties: asset.properties,
        timezone,
        propertiesMetadata,
        visibleProperties: summaryProperties,
        keepPropertyKey: true,
        hideInvalidValues,
      })

      const renderProperties = result.reduce(
        (acc, { propertyKey, propertyValue }) => {
          return { ...acc, [propertyKey]: propertyValue }
        },
        {}
      )
      return {
        ...asset,
        renderProperties,
      }
    })
  }, [
    assets,
    enableSort,
    hideInvalidValues,
    isAscendingOrder,
    propertiesMetadata,
    sortByProperty,
    summaryProperties,
    timezone,
  ])

  const data = useMemo(() => {
    if (currentAsset) {
      return renderPropertiesItem({
        properties: currentAsset.properties,
        timezone,
        propertiesMetadata,
        visibleProperties,
        hideInvalidValues,
      })
    }
    return _.isEmpty(summaryProperties) ? assets : getSummaryData()
  }, [
    assets,
    currentAsset,
    getSummaryData,
    hideInvalidValues,
    propertiesMetadata,
    summaryProperties,
    timezone,
    visibleProperties,
  ])

  const columns = useMemo(() => {
    if (currentAsset) {
      return PROPERTY_TABLE_COLUMNS
    }

    return _.isEmpty(summaryProperties)
      ? [
          {
            Header: relatedAssetRelationshipId,
            accessor: 'displayName',
          },
        ]
      : summaryProperties.map(propertyName => {
          return {
            accessor: `renderProperties.${propertyName}`,
            Header: getPropertyDisplayNameFromMetadata({
              propertiesMetadataKeyByName,
              propertyName,
            }),
          }
        })
  }, [
    currentAsset,
    propertiesMetadataKeyByName,
    relatedAssetRelationshipId,
    summaryProperties,
  ])

  const onSelectedAsset = useCallback(
    asset => {
      if (!currentAsset) {
        setCurrentAsset(asset.original)
      }
    },
    [currentAsset]
  )

  useEffect(() => {
    const newHeader = currentAsset ? (
      <>
        <IconButton
          icon='FiArrowLeft'
          label={`Back to ${relatedAssetRelationshipId} table`}
          onClick={() => setCurrentAsset(undefined)}
          size={16}
        />
        {currentAsset.displayName}
      </>
    ) : undefined
    setHeader(newHeader)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAsset])

  return _.isEmpty(data) ? (
    <NoAvailableWidget
      widgetType={WIDGET_TYPES.table}
      content={NO_DATA_PLACEHOLDER}
    />
  ) : (
    <Table
      theme={theme}
      data={data}
      columns={columns}
      withHeader={!currentAsset}
      tableStyle={tableStyle}
      {...(!currentAsset && { onSelectedRow: onSelectedAsset })}
    />
  )
}

export type RelatedMultiTablesProps = AssetTypeOptions & {
  value: Asset & {
    related: AssetRelationshipMany
  }
  properties?: string[]
}

export const RelatedMultiTables = ({
  value,
  properties: visibleProperties = undefined,
  enableSort = false,
  isAscendingOrder = true,
  sortByProperty,
  ...rest
}: RelatedMultiTablesProps): ReactElement => {
  const { assets = [] } = value?.related || {}
  const { properties: propertiesMetadata = [] } = value?.assetProfile || {}

  const sortsAssets = useMemo(() => {
    return getSortAssets({
      enableSort,
      assets,
      sortByProperty,
      isAscendingOrder,
    })
  }, [assets, enableSort, isAscendingOrder, sortByProperty])

  return (
    <>
      {_.map(sortsAssets, asset => {
        const { id, displayName, properties } = asset
        return (
          <StyledSection key={id}>
            <StyledTitle>{displayName}</StyledTitle>
            <SinglePageTable
              {...rest}
              properties={properties}
              propertiesMetadata={propertiesMetadata}
              visibleProperties={visibleProperties}
              theme={THEMES.light}
            />
          </StyledSection>
        )
      })}
    </>
  )
}
