import styled from '@emotion/styled/macro'
import { css, keyframes } from '@emotion/react'

import { BUTTON_ICON_POSITIONS, ICON_MARGIN } from '../constants'
import { ButtonIconPosition } from '..'

export const getIconMargin = (
  iconPosition: ButtonIconPosition,
  iconOnly?: boolean
): string => {
  if (iconOnly) return ''
  return iconPosition === BUTTON_ICON_POSITIONS.left
    ? `margin-right: ${ICON_MARGIN}`
    : `margin-left: ${ICON_MARGIN}`
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const IconWrapper = styled.span<{
  isLoading?: boolean
  isIconFixed?: boolean
  iconPosition: ButtonIconPosition
  iconSize: number
  iconOnly?: boolean
}>`
  display: inline-flex;
  transition: transform ease 0.5s;
  transform: translateX(0px);

  ${({ iconPosition, iconOnly }) => getIconMargin(iconPosition, iconOnly)};

  ${({ isLoading }) =>
    isLoading
      ? css`
          animation: ${spin} 2s linear infinite;
        `
      : ''}
`
