// libraries
import { useState, useRef, useCallback, ReactElement } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { FileRejection, useDropzone } from 'react-dropzone'
import styled from '@emotion/styled/macro'
import { useAsync } from 'react-use'

// constants
import { FILE_MIME_TYPE_EXTENSIONS_MAPPING } from 'constants/common'
import { BUTTON_VARIANTS } from 'components/common/Button'

// utils
import { showWarn } from 'helpers/message'
import { useUserAbility } from 'hooks'
import { getUser } from 'services/api/user'

// types
import type { User } from 'types/user'

// components
import { UserIcon } from 'components/icons'
import { Loading, ModalFooter, Slider } from 'components/common'

// styles
import scss from './index.module.scss'

const AVATAR_MIN_ZOOM_RANGE = 1
const AVATAR_MAX_ZOOM_RANGE = 5

const AVATAR_EDITOR_DEFAULT_OPTIONS = {
  width: 250,
  height: 250,
  border: [100, 2],
  borderRadius: 125,
  color: [240, 240, 240, 0.6],
  crossOrigin: 'anonymous',
}

const StyledUploaderPlaceholder = styled.div`
  background: ${props => props.theme['secondary-light-500']};
  border: 1px dashed ${props => props.theme['primary-200']};
  border-radius: 5px;
  padding: 80px 0;
`

const StyledOverlay = styled.div`
  text-align: center;
  background: ${props => props.theme['secondary-light-500']};
  margin: 0 5px;
`

const AvatarEditorContainer = ({
  user,
  onChange,
  onCancel,
}: {
  user: User
  onChange: ({ avatar }: { avatar: string }) => void
  onCancel: () => void
}): ReactElement => {
  const imageRef = useRef(null)

  const { id } = user

  const { canUpdate } = useUserAbility({ user })

  const [avatarImage, setAvatarImage] = useState<File | string | null>()

  // refetch the avatarURL on edit to avoid expired image errors
  const avatarState = useAsync(async () => {
    const { avatarUrl: newAvatarUrl } = await getUser(id)
    setAvatarImage(newAvatarUrl)
  }, [])

  const { loading } = avatarState

  const [scale, setScale] = useState(1)

  const onDelete = useCallback(() => {
    setAvatarImage(undefined)
  }, [])

  const onAvatarImageSave = useCallback(() => {
    const avatarEditor = imageRef && imageRef.current
    let newAvatarImage

    if (!avatarEditor) {
      newAvatarImage = ''
    } else {
      newAvatarImage = avatarImage
        ? avatarEditor.getImageScaledToCanvas().toDataURL()
        : ''
    }

    onChange({ avatar: newAvatarImage })
    onCancel()
  }, [avatarImage, onCancel, onChange])

  const onDropAccepted = useCallback((dropped: File[]) => {
    setAvatarImage(dropped[0])
  }, [])

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const {
      file: { name },
    } = fileRejections[0]
    showWarn(`Please select a valid image file. "${name}" cannot be used.`)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    noClick: !!avatarImage,
    onDropAccepted,
    onDropRejected,
    accept: FILE_MIME_TYPE_EXTENSIONS_MAPPING.image,
    multiple: false,
    noKeyboard: true,
  })

  const renderZoomSlider = () => (
    <div className={scss.slider}>
      <Slider
        value={scale}
        step={0.2}
        range={[AVATAR_MIN_ZOOM_RANGE, AVATAR_MAX_ZOOM_RANGE]}
        onChange={setScale}
        changeOnBlur={false}
      />
    </div>
  )

  return (
    <>
      <div className={scss.container}>
        <div className={scss.dropzone}>
          {loading ? (
            <Loading />
          ) : (
            <div {...getRootProps()}>
              {avatarImage ? (
                <>
                  <StyledOverlay>
                    <span className='smallText semiBold'>
                      Click and drag to move
                    </span>
                  </StyledOverlay>
                  <AvatarEditor
                    {...AVATAR_EDITOR_DEFAULT_OPTIONS}
                    ref={imageRef}
                    image={avatarImage}
                    scale={scale}
                  />
                </>
              ) : (
                <StyledUploaderPlaceholder>
                  <UserIcon />
                  <div className='text-secondary smallText semiBold'>
                    <div className={scss.text}>
                      Drag and drop an image file or{' '}
                      <label htmlFor='file-upload' className={scss.fileUpload}>
                        click here to upload
                      </label>
                    </div>
                  </div>
                </StyledUploaderPlaceholder>
              )}
              <input
                {...getInputProps()}
                id='file-upload'
                style={{ display: 'none' }}
              />
            </div>
          )}
        </div>
        {avatarImage && renderZoomSlider()}
      </div>
      <ModalFooter
        onCancel={onCancel}
        submitContent='Save'
        onSubmit={onAvatarImageSave}
        canSubmit={canUpdate}
        {...(avatarImage && {
          buttons: [
            {
              id: 'avatar-delete-btn',
              variant: BUTTON_VARIANTS['danger-link'],
              children: 'Remove',
              onClick: onDelete,
              buttonPosition: 'left',
            },
          ],
        })}
      />
    </>
  )
}

export default AvatarEditorContainer
