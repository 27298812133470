// libraries
import _ from 'lodash'

// utils
import { getArgs, getQueryFields } from 'helpers/graphql'
import {
  listEntitiesGraphql,
  getEntitiesQuery,
  MutateEntity,
  mutateEntity,
} from 'services/api/utils'
import { stringToBoolean } from 'helpers/utils'

import type {
  CamAssetReference,
  AssetEquipment,
} from 'app/MissionControlMethaneSolution/types/graphql'
import type {
  DetectionData,
  DetectionSite,
} from 'app/MissionControlMethaneSolution/types/detection'
import type { QueryParams, SelectedFields } from 'types/services'

import {
  deserializeEmissionObservation,
  getEmissionObservationFields,
} from './emissionObservation'

const DOMAIN = 'detection'
const queryDomain = `${DOMAIN}s`

export const deserializeSite = (
  asset?: CamAssetReference['asset']
): DetectionSite => {
  const { assetReference, geometryJson, properties } = asset ?? {}
  return {
    assetReference,
    properties: {
      ...properties,
      // currently all properties are VARCHAR
      // https://sensorup-inc.slack.com/archives/C06QCP80K50/p1712088554998899?thread_ts=1712088429.473259&cid=C06QCP80K50
      isDAC: stringToBoolean(properties?.isDAC),
    },
    // the name can be ' ' and we need to handle using trim
    name: _.trim(_.get(properties, 'name')) || 'Unknown Name',
    ...(geometryJson && {
      observations: [{ type: 'Feature', properties, geometry: geometryJson }],
    }),
    isAssetSite: true,
    id: properties?.id,
  }
}

const mutateDetection =
  ({
    pickFields,
    omitFields,
    ...restProps
  }: Omit<MutateEntity, 'queryDomain'> & SelectedFields) =>
  ({
    id,
    fieldsWithArguments,
    ...rest
  }: { id?: string; fieldsWithArguments?: QueryParams } & QueryParams) =>
    mutateEntity<DetectionData>({
      queryDomain,
      responseFields: {
        [DOMAIN]: getEmissionObservationFields({ pickFields, omitFields }),
      },
      responsePath: [DOMAIN],
      withIdentifier: false,
      ignoreError: true,
      postProcessFn: deserializeEmissionObservation,
      ...restProps,
    })(id, rest, fieldsWithArguments) as Promise<{
      data: DetectionData
      error?: string
    }>

export const createDetection = mutateDetection({
  fnName: 'createDetection',
  variableFormat: 'CreateDetectionInput!',
  pickFields: [
    'id',
    'shortId',
    'camAssetReference',
    'wip',
    'closed',
    'sourceAssetReference',
  ],
})

export const resolveDetection = mutateDetection({
  fnName: 'resolveDetection',
  responseFields: {},
  variableFormat: 'ResolveDetectionInput!',
})

export const getMediaUploadUrlsQuery = (
  files: File[]
): Record<string, unknown> => {
  const mediaKeys = _.map(files, 'name')
  return {
    [DOMAIN]: {
      mediaUploadUrls: {
        ...getArgs({ mediaKeys }),
      },
    },
  }
}

const siteEquipmentQueryDomain = 'assetEquipments'

export const getSiteEquipments = listEntitiesGraphql<AssetEquipment>({
  queryDomain: siteEquipmentQueryDomain,
  getQueryFn: getEntitiesQuery({
    queryDomain: siteEquipmentQueryDomain,
    getFieldsFn: getQueryFields({
      assetReference: true,
      id: true,
      properties: getArgs({ properties: ['name', 'FLOC', 'isAttributable'] }),
      equipmentComponents: {
        name: true,
      },
    }),
    variables: {
      filter: 'AssetEquipmentFilter',
    },
  }),
})
