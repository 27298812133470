import { PropsWithChildren } from 'react'
import { useMount } from 'react-use'
import log from 'helpers/log'
import { isDevEnvironment } from 'helpers/utils'
import { useConfigStateValue } from './ConfigContext'

const HeapProvider = ({
  children,
  heapAppId,
}: PropsWithChildren<{ heapAppId: string }>) => {
  const {
    logging: { environment },
  } = useConfigStateValue()

  useMount(() => {
    const isDevEnv = isDevEnvironment()

    const disableHeapTracking = environment.endsWith('-sbx') || isDevEnv

    if (disableHeapTracking) {
      log.info('Skip Heap')

      return
    }

    if (!heapAppId) {
      log.error('Missing Heap ID')
      return
    }

    // the following code is from https://developers.heap.io/docs/web
    /* eslint-disable */
    window.heapReadyCb = window.heapReadyCb || []
    window.heap = window.heap || []

    heap.load = function (e, t = {}) {
      window.heap.envId = e
      window.heap.clientConfig = t
      window.heap.clientConfig.shouldFetchServerConfig = !1
      const a = document.createElement('script')
      a.type = 'text/javascript'
      a.async = !0
      a.src = `https://cdn.us.heap-api.com/config/${e}/heap_config.js`
      const r = document.getElementsByTagName('script')[0]
      r.parentNode.insertBefore(a, r)
      const n = [
        'init',
        'startTracking',
        'stopTracking',
        'track',
        'resetIdentity',
        'identify',
        'getSessionId',
        'getUserId',
        'getIdentity',
        'addUserProperties',
        'addEventProperties',
        'removeEventProperty',
        'clearEventProperties',
        'addAccountProperties',
        'addAdapter',
        'addTransformer',
        'addTransformerFn',
        'onReady',
        'addPageviewProperties',
        'removePageviewProperty',
        'clearPageviewProperties',
        'trackPageview',
      ]
      const i = function (e) {
        return function () {
          const t = Array.prototype.slice.call(arguments, 0)
          window.heapReadyCb.push({
            name: e,
            fn() {
              heap[e] && heap[e].apply(heap, t)
            },
          })
        }
      }
      for (let p = 0; p < n.length; p++) heap[n[p]] = i(n[p])
      log.info('Heap loaded successfully')
    }
    heap.load(heapAppId, {
      enableSecureCookies: true,
    })
    /* eslint-enable */
  })

  return <>{children}</>
}

export default HeapProvider
