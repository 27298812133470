import { useCallback } from 'react'
import _ from 'lodash'

// constants
import { USER_PREFERENCES } from 'constants/settings'

// utils
import { useAuthStateValue } from 'contexts'
import { useCurrentUser } from 'hooks'
import { omitStaleMethaneEntities } from 'helpers/utils'

import type { Payload } from 'types/common'
import type { FilterSpec, Filters } from 'types/filter'

/** Returns the function that can be used to call API to update filter preferences */
const useUpdateFilterPreferences = ({
  entity,
  customFiltersSpecs,
  onChangeCallback,
}: {
  entity: string
  customFiltersSpecs?: FilterSpec[]
  onChangeCallback?: (filters: Filters) => void
}): {
  updateFilterPreferences: (
    payload: Payload<Payload | string | string[] | boolean | null | undefined>
  ) => Promise<void>
} => {
  const { currentUser } = useAuthStateValue()

  const { filters } = currentUser.preferences

  const { updateCurrentUserPreference } = useCurrentUser()

  const updateFilterPreferences = useCallback(
    async (newFilterValues: Payload<string | string[]>) => {
      const newValidFilterValues = _.omitBy(newFilterValues, _.isNil)
      onChangeCallback?.(newValidFilterValues)

      const newEntityFilters = customFiltersSpecs
        ? _.reduce(
            customFiltersSpecs,
            (acc, specs) => {
              const { filterKey, key } = specs
              const filterValue = newValidFilterValues[key]
              return _.isNil(filterValue)
                ? acc
                : { ...acc, [filterKey ?? key]: filterValue }
            },
            {}
          )
        : newValidFilterValues
      if (_.isEqual(newEntityFilters, filters?.[entity])) return

      const payload = {
        preference: USER_PREFERENCES.filters,
        value: {
          ...omitStaleMethaneEntities(filters),
          [entity]: newEntityFilters,
        },
      }
      updateCurrentUserPreference(payload, USER_PREFERENCES.filters)
    },
    [
      onChangeCallback,
      customFiltersSpecs,
      filters,
      entity,
      updateCurrentUserPreference,
    ]
  )

  return { updateFilterPreferences }
}

export default useUpdateFilterPreferences
