// libraries
import { ReactElement, ReactNode } from 'react'

// styles
import scss from './index.module.scss'

const FullPageLayout = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  return <div className={scss.app}>{children}</div>
}

export default FullPageLayout
