// libraries
import { ReactElement, useEffect, useMemo, useRef } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled/macro'

// constants
import { THEMES } from 'constants/colour'

// components
import { IconButton } from 'components/common'
import IssueOverview from 'components/issue/IssueItemDetail/IssueOverview'

// types
import type { ThemeType } from 'types/common'
import type { IssueGeojson } from 'types/issue'

type IssueCardProps = {
  isActive: boolean
  isLightTheme: boolean
}

const IssueCard = styled.div<IssueCardProps>`
  border-bottom: ${props =>
    props.isLightTheme ? '1px solid #eee' : '1px solid #ffffff24'};
  color: ${props => (props.isLightTheme ? '#000' : '#fff')};
  padding: 15px 15px 15px 2px;
  background-color: ${props =>
    props.isLightTheme && props.isActive ? '#F4F7FA' : ''};
  & > a {
    background-color: ${props => (props.isLightTheme ? '#fff' : '')};
  }
  &:hover {
    background-color: ${props => (props.isLightTheme ? '#F4F7FA' : '')};
  }
`

const IssueListItem = ({
  index,
  issue,
  isActive = false,
  isSelected = false,
  selectedIssuesActions,
  withBulkUpdate,
  theme,
  onCardClick,
  setItemSize = _.noop,
  getMapInteractions,
}: {
  issue: IssueGeojson
  theme: ThemeType
}): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  const issueItemRef = useRef()

  const {
    properties: { name },
  } = issue

  useEffect(() => {
    if (issueItemRef?.current) {
      const { height } = issueItemRef.current.getBoundingClientRect()
      setItemSize(index, height)
    }
  }, [index, setItemSize])

  const mapInteractions = useMemo(
    () => getMapInteractions && getMapInteractions(issue),
    [getMapInteractions, issue]
  )

  return (
    <IssueCard
      ref={issueItemRef}
      {...mapInteractions}
      className='d-flex'
      isLightTheme={isLightTheme}
      isActive={isActive}
    >
      {withBulkUpdate && (
        <div className='flex-shrink-0'>
          <IconButton
            icon={isSelected ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank'}
            onClick={() => {
              selectedIssuesActions.toggle(name)
            }}
            size={24}
            style={{ marginRight: '12px' }}
          />
        </div>
      )}
      <div className='flex-grow-1' onClick={() => onCardClick(issue)}>
        <IssueOverview issue={issue} theme={theme} />
      </div>
    </IssueCard>
  )
}

export default IssueListItem
