import styled from '@emotion/styled/macro'

export { default as EmailInputField } from './EmailInputField'
export { default as PasswordInputField } from './PasswordInputField'
export { default as SuccessMessage } from './SuccessMessage'

export const Title = styled.h2`
  color: ${props => props.theme['primary-700']};
  font-weight: 400;
  font-size: 24px;
  height: 41px;
  text-align: center;
`

export const Subtitle = styled.h2`
  color: #000000;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
`
