import styled from '@emotion/styled/macro'
// For some reason the import from 'components/common' doesn't work
import Button from 'components/common/Button'

export const EditAvatarBtn = styled(Button)`
  position: relative;
  width: 24px;
  height: 24px;
  padding: 0;
  color: #7a7a7a;
  background-color: #fff;
  border: none;
  border-radius: 50%;
`
