import _ from 'lodash'
import { ReactElement, ReactNode } from 'react'
import styled from '@emotion/styled/macro'

import scss from './index.module.scss'

const StyledTab = styled.div<{
  isActive: boolean
}>`
  border-bottom: 2px solid
    ${props => (props.isActive ? props.theme.primary : 'none')};
  cursor: pointer;
  font-weight: ${props => (props.isActive ? '600' : '400')};
`

export type TabsProps = {
  tabs: {
    label?: ReactNode
    value: string
  }[]
  className?: string
  selectedTab?: string
  onTabSelected: (v: string) => void
  withBottomBorder?: boolean
}

const Tabs = ({
  tabs,
  className,
  selectedTab,
  onTabSelected,
  withBottomBorder = false,
}: TabsProps): ReactElement => {
  return (
    <div
      className={`${scss.tabs} ${className} ${
        withBottomBorder ? scss.border : ''
      }`}
    >
      <ul className={scss.tabNav}>
        {_.map(tabs, ({ label, value }) => (
          <StyledTab
            className={scss.tabHeader}
            isActive={selectedTab === value}
            onClick={() => onTabSelected(value)}
            key={value}
          >
            {label ?? _.capitalize(value)}
          </StyledTab>
        ))}
      </ul>
    </div>
  )
}

export default Tabs
