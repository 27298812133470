// libraries
import { useMemo, ReactElement } from 'react'
import _ from 'lodash'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import { useToggle } from 'react-use'

// constants
import { URLS } from 'constants/route'
import { HOME_PAGE_URL } from 'constants/common'

// utils
import { useAuthStateValue, useStaticResourceStateValue } from 'contexts'
import Auth from 'services/authentication'
import { useBranding } from 'hooks'
import { getRouteUrlWithValues } from 'helpers/utils'

// components
import { Avatar } from 'components/common'

import type { LayoutOptions } from 'types/route'

import { NavItem } from './components'
import scss from './index.module.scss'

const StyledSidebar = styled.div`
  background-color: ${props => props.theme['primary-900']};
`

const Sidebar = ({
  navLinkSpecs,
}: Pick<LayoutOptions, 'navLinkSpecs'>): ReactElement => {
  const [isPickerVisible, togglePickerVisible] = useToggle(false)

  const { currentUser, isExternal } = useAuthStateValue()

  const navigate = useNavigate()

  const { Icons } = useStaticResourceStateValue()
  const SettingIcon = Icons.AiFillSetting

  const { externalDashboardUrl } = Auth

  const { logo } = useBranding()
  const { navbarLogo } = logo

  const logoURL = useMemo(() => _.get(logo, navbarLogo), [logo, navbarLogo])

  const memoizedNavLinks = useMemo(() => {
    const navbarLogoIcon = (
      <img
        alt='logo'
        className={scss.sidebarLogo}
        src={`${window.location.origin}${logoURL}`}
      />
    )

    return (
      <>
        {isExternal ? (
          <div
            className={scss.link}
            onClick={() => window.location.replace(externalDashboardUrl)}
          >
            {navbarLogoIcon}
          </div>
        ) : (
          <NavLink to={HOME_PAGE_URL}>{navbarLogoIcon}</NavLink>
        )}
        {_.map(navLinkSpecs, link => (
          <NavItem key={`${link.name}-${link.path}`} {...link} />
        ))}
      </>
    )
  }, [externalDashboardUrl, isExternal, logoURL, navLinkSpecs])

  const currentUserProfile = (
    <>
      {currentUser && (
        <div
          data-testid='sidebar-user-avatar'
          className='cursorPointer'
          onClick={() =>
            navigate(getRouteUrlWithValues(URLS.SETTINGS, { currentUser }))
          }
        >
          <Avatar
            className={scss.sidebarAvatar}
            user={currentUser}
            tooltip={false}
            onHover={togglePickerVisible}
            hover={isPickerVisible}
            hoverIcon={<SettingIcon size={24} />}
          />
        </div>
      )}
    </>
  )

  return (
    <StyledSidebar className={scss.sidebar}>
      {memoizedNavLinks}
      <div className={scss.bottomWrapper}>{currentUserProfile}</div>
    </StyledSidebar>
  )
}

export default Sidebar
