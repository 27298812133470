import { ReactElement } from 'react'
import styled from '@emotion/styled/macro'
import { useTheme } from '@emotion/react'

import {
  ColumnOptions,
  SetVisibleColumns,
} from 'components/common/DataTable/useDataTableColumns'

import * as Icons from 'components/icons'
import { TableIcon } from 'svg'
import { EmptyState } from 'components/common'

import scss from './index.module.scss'

const LinkBtn = styled.span`
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
`

const EmptyColumnsList = ({
  allAvailableColumns,
  setVisibleColumns,
}: {
  allAvailableColumns: ColumnOptions[]
  setVisibleColumns: SetVisibleColumns
}): ReactElement => {
  const theme = useTheme()

  return (
    <EmptyState image={TableIcon} title='No columns added'>
      <LinkBtn
        onClick={() => {
          // Same as 'Select all' in the column toggle
          setVisibleColumns(allAvailableColumns)
        }}
      >
        Show all columns
      </LinkBtn>
      <p className={scss.textWithIcon}>
        or select using
        <Icons.ColumnToggle
          className='mx-1'
          size={13}
          color={theme.secondary}
        />
        hide/show column
      </p>
    </EmptyState>
  )
}

export default EmptyColumnsList
