// libraries
import { ReactElement, useCallback } from 'react'
import _ from 'lodash'
import { useTheme } from '@emotion/react'

// constants
import {
  BUTTON_VARIANTS,
  BUTTON_ICON_POSITIONS,
} from 'components/common/Button'
import {
  BADGE_TYPES,
  GALLERY_LIST_TYPES,
  GALLERY_LIST_TYPES_ICONS,
} from 'constants/common'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import {
  SortButton,
  SearchBar,
  IconButton,
  Button,
  Badge,
  TableColumnToggler,
  TableDateRangeFilter,
  Tooltip,
} from 'components/common'

import type {
  Filters,
  OnFiltersChange,
  DateFilterOptions,
  FilterSpec,
} from 'types/filter'
import type { OnGroupByChange } from 'types/gallery'
import type { Options, Payload, ListConditions } from 'types/common'
import type {
  ColumnsWithGroupable,
  ColumnOptions,
} from 'components/common/DataTable/useDataTableColumns'

import { GroupByDropdown } from './components'
import QuickFilters from './components/QuickFilters'

import scss from './index.module.scss'

const ICON_SIZE = 16

export type ToolboxOptions = {
  show?: boolean
  hideFiltersButton?: boolean
  enableSearch: boolean
  enableListTypes: string[]
  sortOptions: Options
  displaySort: boolean
  searchBarPlaceholder: string
  changeOnBlur: boolean
  enableSearchFields: boolean
  enableSearchDebounce?: boolean
  searchFieldsLoading: boolean
  searchFieldsOptions: { value: string; label: string }[]
  defaultSearchField: string
  tableGroupableColumns: ColumnsWithGroupable
  enableDateFilter?: boolean
  dateFilterOptions?: DateFilterOptions
  enableExport?: boolean
  enableFilter?: boolean
}

export type ToolboxProps = {
  onChange: (v: ListConditions) => void
  onRefresh?: () => void
  onExport?: () => void
  options?: ToolboxOptions
  conditions?: ListConditions
  filterValues?: Filters
  filtersCount?: number
  toggleShowFilters?: (v: boolean) => void
  isShowingFilters?: boolean
  columns?: ColumnOptions[]
  visibleColumns?: ColumnOptions[]
  setVisibleColumns?: (columns: ColumnOptions[]) => void
  onGroupByChange?: OnGroupByChange
  onFiltersChange?: OnFiltersChange
  renderAdditionalTools?: () => ReactElement
  filtersSpecs: FilterSpec[]
}

export const TABLE_GROUP_BY_OPTION = {
  label: 'None',
  value: 'default_none_table_group_by',
}

const Toolbox = ({
  conditions,
  options,
  onChange,
  onRefresh,
  onExport,
  filterValues,
  filtersCount = 0,
  toggleShowFilters,
  isShowingFilters,
  columns,
  visibleColumns,
  setVisibleColumns,
  onGroupByChange,
  onFiltersChange,
  renderAdditionalTools,
  filtersSpecs,
}: ToolboxProps): ReactElement => {
  const { primary } = useTheme()

  const {
    hideFiltersButton = false,
    enableSearch = true,
    enableListTypes = [GALLERY_LIST_TYPES.card, GALLERY_LIST_TYPES.table],
    sortOptions = [],
    searchBarPlaceholder,
    enableSearchFields = false,
    defaultSearchField,
    enableSearchDebounce,
    searchFieldsLoading = false,
    searchFieldsOptions = [],
    displaySort = true,
    tableGroupableColumns,
    enableDateFilter,
    dateFilterOptions,
    enableExport = true,
  } = options ?? {}

  const {
    sortField = _.get(sortOptions, '[0].value') ?? 'audit.updatedTime',
    ascOrder = true,
    listType = GALLERY_LIST_TYPES.card,
    tableGroupedBy,
  } = conditions || {}

  const onChangeConditions = useCallback(
    (payload: Payload) => {
      onChange({ ...conditions, ...payload })
    },
    [conditions, onChange]
  )

  const renderSearchBar = () =>
    enableSearch && (
      <div className={scss.toolboxItem}>
        <SearchBar
          onChange={({ selectedFields, value }) => {
            onChangeConditions({
              ...(enableSearchFields && {
                selectedSearchFields: selectedFields,
              }),
              search: value,
              isSearching: !!value,
            })
          }}
          placeholder={searchBarPlaceholder}
          className='me-1'
          enableSearchFields={enableSearchFields}
          defaultSearchField={defaultSearchField}
          enableSearchDebounce={enableSearchDebounce}
          fieldsLoading={searchFieldsLoading}
          fieldsOptions={searchFieldsOptions}
        />
      </div>
    )

  const renderDateFilter = () =>
    enableDateFilter &&
    !_.isEmpty(dateFilterOptions) &&
    _.isFunction(onFiltersChange) && (
      <div className={scss.toolboxItem}>
        <TableDateRangeFilter
          filterValues={filterValues}
          dateFilterOptions={dateFilterOptions}
          onFiltersChange={onFiltersChange}
        />
      </div>
    )

  const renderQuickFilters = () => {
    const quickFilters = _.filter(filtersSpecs, 'isQuickFilter')

    return quickFilters.length ? (
      <QuickFilters
        quickFilterSpecs={quickFilters}
        filterValues={filterValues}
        onFiltersChange={onFiltersChange}
      />
    ) : null
  }

  const renderSortBy = () =>
    !_.isEmpty(sortOptions) &&
    displaySort &&
    listType !== GALLERY_LIST_TYPES.table && (
      <SortButton
        className={scss.toolboxItem}
        sortOptions={sortOptions}
        property={sortField}
        onChange={val => onChangeConditions({ sortField: val })}
        ascSortOrder={ascOrder}
        onOrderChange={val => onChangeConditions({ ascOrder: val })}
      />
    )

  const renderFilterToggle = () =>
    _.isFunction(toggleShowFilters) &&
    !hideFiltersButton && (
      <div className={scss.toolboxItem}>
        <Button
          className={`${scss.toolbarButton} ${
            isShowingFilters ? '' : 'text-secondary'
          } p-2 me-2`}
          variant={BUTTON_VARIANTS.link}
          onClick={() => toggleShowFilters(!isShowingFilters)}
          icon='MdFilterAlt'
          iconPosition={BUTTON_ICON_POSITIONS.left}
        >
          <span>Filter</span>
          {filtersCount > 0 && (
            <Badge
              content={filtersCount}
              className='ms-2'
              type={BADGE_TYPES.primary}
            />
          )}
        </Button>
      </div>
    )

  const renderListTypeSwither = () =>
    !_.isEmpty(enableListTypes) && (
      <div className={`${scss.toolboxItem} d-flex align-items-center`}>
        {enableListTypes.map((type: string) => (
          <div className={scss.toolboxGroupItem} key={type}>
            <Tooltip
              placement={TOOLTIP_PLACEMENT.top}
              trigger={['hover']}
              overlay={<span>{_.startCase(type)}</span>}
            >
              <IconButton
                icon={GALLERY_LIST_TYPES_ICONS[type]}
                size={ICON_SIZE}
                width={ICON_SIZE}
                height={ICON_SIZE}
                style={{ color: listType === type ? primary : '#76787F' }}
                className={listType === type ? scss.activeIcon : ''}
                onClick={() => onChangeConditions({ listType: type })}
              />
            </Tooltip>
          </div>
        ))}
      </div>
    )

  const renderTableGroupBy = () =>
    !_.isEmpty(tableGroupableColumns) &&
    listType === GALLERY_LIST_TYPES.table && (
      <GroupByDropdown
        btnClassName={`${scss.toolbarButton} text-secondary p-2`}
        tableGroupedBy={tableGroupedBy}
        tableGroupableColumns={tableGroupableColumns}
        onChange={(v: string) => {
          const newValue = v === TABLE_GROUP_BY_OPTION.value ? undefined : v
          onChangeConditions({ tableGroupedBy: newValue })
          if (onGroupByChange) {
            onGroupByChange({ newTableGroupedBy: newValue, onFiltersChange })
          }
        }}
      />
    )

  const renderListRefetch = () =>
    onRefresh && (
      <div className={scss.toolboxItem}>
        <IconButton
          icon='MdRefresh'
          className={scss.refreshBtn}
          size={ICON_SIZE}
          onClick={() => onRefresh()}
        />
      </div>
    )

  const renderDataExport = () =>
    enableExport &&
    onExport && (
      <div className={scss.toolboxItem}>
        <IconButton
          icon='MethaneDashboardExport'
          onClick={onExport}
          width={14}
        />
      </div>
    )

  const renderTableColumnToggler = () =>
    (listType === GALLERY_LIST_TYPES.table ||
      listType === GALLERY_LIST_TYPES.split) &&
    !!columns?.length && (
      <TableColumnToggler
        className={scss.toolboxItem}
        columns={columns}
        visibleColumns={visibleColumns as ColumnOptions[]}
        setVisibleColumns={
          setVisibleColumns as (selectedOptions: ColumnOptions[]) => void
        }
      />
    )

  return (
    <div className='d-flex align-items-center'>
      {/* Render the search always first because it changes its width on click */}
      {renderSearchBar()}
      {renderDateFilter()}
      {renderQuickFilters()}
      {renderAdditionalTools && (
        <div className={scss.toolboxItem}> {renderAdditionalTools?.()}</div>
      )}
      {renderSortBy()}
      {renderFilterToggle()}
      {renderTableGroupBy()}
      {renderTableColumnToggler()}
      {renderListTypeSwither()}
      {renderListRefetch()}
      {renderDataExport()}
    </div>
  )
}

export default Toolbox
