import { ReactElement } from 'react'
import styled from '@emotion/styled/macro'

import { VerticalDivider } from 'components/common'

export const AddonLabel = styled.span<{ fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
  line-height: 19px;
  color: ${({ theme }) => theme.secondary};
`

const InputTextAddon = ({
  label,
  isLeft,
  fontSize = '14px',
  dividerHeight = 18,
}: {
  label: string
  isLeft?: boolean
  fontSize?: string
  dividerHeight?: number
}): ReactElement => (
  <>
    {!isLeft && <VerticalDivider height={dividerHeight} />}
    <AddonLabel fontSize={fontSize}>{label}</AddonLabel>
    {isLeft && <VerticalDivider height={dividerHeight} />}
  </>
)

export default InputTextAddon
