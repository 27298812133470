// libraries
import styled from '@emotion/styled'

type StyledTreeTableProps = {
  isLightTheme: boolean
  hideHeader?: boolean
}

export const StyledTreeTable = styled.div<StyledTreeTableProps>`
  .p-treetable .p-treetable-tbody > tr {
    background: ${props => (props.isLightTheme ? '#fff' : props.theme.dark)};
    color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-700']
        : props.theme['secondary-light-600']};
    transition: box-shadow 0.2s;
    border-left: 1px solid
      ${props =>
        props.isLightTheme
          ? `${props.theme['secondary-100']}`
          : `${props.theme['primary-800']}`};
    border-right: 1px solid
      ${props =>
        props.isLightTheme
          ? `${props.theme['secondary-100']}`
          : `${props.theme['primary-800']}`};
    border-bottom: 1px solid
      ${props =>
        props.isLightTheme
          ? `${props.theme['secondary-100']}`
          : `${props.theme['primary-800']}`};
  }

  .p-treetable .p-treetable-tbody > tr:first-of-type {
    ${props =>
      props?.hideHeader
        ? ` border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border: 1px solid
            ${
              props.isLightTheme
                ? `${props.theme['secondary-100']}`
                : `${props.theme['primary-800']}`
            };`
        : ''};
  }

  .p-treetable .p-treetable-tbody > tr:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .p-treetable .p-column-resizer {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 0.5rem;
    height: 100%;
    padding: 0px;
    cursor: col-resize;
    border: 1px solid transparent;
  }
  .p-treetable .p-treetable-thead > tr > th {
    background: transparent !important;
    padding: 12px 16px;
    font-size: 10px;
    border: none;
    color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-700']
        : props.theme['secondary-light-600']};
  }

  .p-treetable .p-treetable-thead > tr > th:not(:last-child):before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 1px;
    background: ${props =>
      props.isLightTheme
        ? `${props.theme['secondary-100']}`
        : `${props.theme['primary-800']}`};
  }

  .p-treetable .p-treetable-tbody > tr > td {
    height: 40px;
    padding: 10px 16px;
    font-size: 12px;
    border: none;
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
    white-space: nowrap; /* Prevent the text from wrapping */
  }
  button.p-treetable-toggler.p-link.p-unselectable-text svg {
    width: 12px;
  }
  .p-column-header-content svg {
    width: 12px;
  }

  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: ${props =>
      props.isLightTheme
        ? props.theme['secondary-light-500']
        : props.theme['primary-900']};
    color: ${props => props.theme.primary};
  }

  .p-treetable.p-treetable-selectable
    .p-treetable-tbody
    > tr.p-selectable-row:focus {
    outline: 0 none;
  }

  .p-treetable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem ${props => props.theme.primary};
    outline: 0 none;
  }

  .p-treetable .p-sortable-column.p-highlight {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-treetable.p-treetable-selectable
    .p-treetable-tbody
    > tr.p-selectable-row:not(.p-highlight):not(.p-treetable-emptymessage):hover {
    background: ${props =>
      props.isLightTheme
        ? props.theme['secondary-light-500']
        : props.theme['primary-900']};
    color: ${props => props.theme.primary};
  }

  .p-treetable.p-treetable-selectable
    .p-treetable-tbody
    > tr.p-selectable-row:not(.p-highlight):not(.p-treetable-emptymessage):hover
    .userPicker {
    color: ${props => props.theme.primary};
  }

  .p-treetable .p-treetable-loading-overlay {
    /* Just preventing interactions with the table while it's loading */
    background-color: transparent;
    border-radius: 4px;
  }

  .p-treetable-thead {
    background: ${props =>
      props.isLightTheme
        ? `${props.theme['secondary-50']}`
        : `${props.theme['primary-900']}`} !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid
      ${props =>
        props.isLightTheme
          ? `${props.theme['secondary-100']}`
          : `${props.theme['primary-800']}`};
    ${props => (props?.hideHeader ? `display: none !important;` : '')};
  }

  .p-treetable-resizable .p-treetable-thead > tr > th {
    white-space: normal;
  }

  .p-treetable .p-treetable-tbody > tr:hover,
  .p-treetable .p-treetable-tbody > tr.p-highlight:hover {
    background-color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-light-500']
        : props.theme['primary-900']};
    color: ${props => props.theme.primary};
    cursor: pointer;
  }

  .p-treetable .p-treetable-tbody > tr.p-highlight:hover {
    background-color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-light-600']
        : props.theme['primary-800']};
  }
  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${props => props.theme.primary};
  }

  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: ${props => props.theme.primary};
  }

  .p-treetable .p-treetable-tbody .child-row {
    background-color: ${props => props.theme['secondary-50']};
  }
`
