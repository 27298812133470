// libraries
import {
  Ref,
  CSSProperties,
  ReactElement,
  forwardRef,
  memo,
  ClipboardEvent,
} from 'react'
import styled from '@emotion/styled/macro'
import _ from 'lodash'

// components
import { TextInput } from 'components/common'
import Button, { BUTTON_VARIANTS } from 'components/common/Button'
import * as Icons from 'components/icons'

// utils
import { stopEventDefaultAndPropagation } from 'helpers/utils'

// types
import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import type { Transform } from '@dnd-kit/utilities'

// styles
import scss from './index.module.scss'

export interface Props {
  onChange: ({
    index,
    value,
    label,
  }: {
    index: number
    value: string
    label: string
  }) => void
  dragOverlay?: boolean
  color?: string
  disabled?: boolean
  dragging?: boolean
  handle?: boolean
  handleProps?: unknown
  height?: number
  index: number
  fadeIn?: boolean
  transform?: Transform | null
  listeners?: DraggableSyntheticListeners
  sorting?: boolean
  style?: CSSProperties
  transition?: string | null
  wrapperStyle?: CSSProperties
  item: { label: string; value: string; id: string }[]
  onRemove?(): void
  renderItem?(args: {
    dragOverlay: boolean
    dragging: boolean
    sorting: boolean
    index: number
    fadeIn: boolean
    listeners: DraggableSyntheticListeners
    ref: Ref<HTMLElement>
    style: CSSProperties | undefined
    transform: Props['transform']
    transition: Props['transition']
    value: Props['value']
  }): ReactElement
  isCheckboxList: boolean
  displayItemLabelColumnOnly: boolean
  handlePaste?: (e: ClipboardEvent<HTMLInputElement>) => void
}

const Container = styled.li<Props>`
  input {
    background-color: ${props =>
      props.dragging && props.theme['secondary-light-500']};
  }

  &:hover {
    background: ${props => props.theme['secondary-light-500']};
    input {
      background: ${props => props.theme['secondary-light-500']};
    }
  }
`

const Item = memo(
  forwardRef<HTMLLIElement, Props>(
    (
      {
        dragging,
        index,
        listeners,
        disabled,
        onRemove = _.noop,
        style,
        item,
        onChange,
        isCheckboxList = false,
        displayItemLabelColumnOnly = true,
        handlePaste,
      },
      ref
    ) => {
      const { label, value: optionValue } = item || {}

      const sharedProps = {
        disabled,
        onPaste: (e: ClipboardEvent<HTMLInputElement>) => {
          handlePaste(e)
          stopEventDefaultAndPropagation(e)
        },
      }

      return (
        <Container
          ref={ref}
          className={scss.item}
          dragging={dragging}
          style={style}
        >
          {!disabled && (
            <div className={scss.handle} {...listeners}>
              <Icons.IconDragThree />
            </div>
          )}
          <div className='row g-0'>
            {isCheckboxList ? (
              <div className='col-12'>
                <TextInput
                  value={optionValue}
                  placeholder='Add value'
                  onChange={newValue =>
                    onChange({
                      ...item,
                      index,
                      value: newValue,
                      label: newValue,
                    })
                  }
                  {...sharedProps}
                />
              </div>
            ) : (
              <>
                <div
                  className={displayItemLabelColumnOnly ? 'col-12' : 'col-6'}
                >
                  <TextInput
                    value={label}
                    className={scss.optionText}
                    onChange={newLabel => {
                      onChange({
                        ...item,
                        index,
                        label: newLabel,
                        value: item.value ? item.value : _.snakeCase(newLabel),
                      })
                    }}
                    placeholder='Add option'
                    {...sharedProps}
                  />
                </div>
                {!displayItemLabelColumnOnly && (
                  <div className={`col-6 ${scss.borderLeft}`}>
                    <TextInput
                      className={scss.optionValue}
                      value={optionValue}
                      onChange={newValue =>
                        onChange({ ...item, index, value: newValue })
                      }
                      placeholder='Add value'
                      {...sharedProps}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {!disabled && (
            <Button
              variant={BUTTON_VARIANTS.link}
              iconSize={14}
              icon='IconTrash'
              className={scss.trashIcon}
              onClick={onRemove}
            />
          )}
        </Container>
      )
    }
  )
)

export default Item
