// libraries
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

// utils
import { switchcase } from 'helpers/utils'
import type { MapStyleSize } from 'types/common'

// components
import Button from 'components/common/Button'

// constants
import { MAP_STYLE_SIZES } from 'constants/common'

const getItemSize = (size: MapStyleSize): number => {
  return switchcase({
    [MAP_STYLE_SIZES.small]: 40,
    [MAP_STYLE_SIZES.large]: 82,
  })(72)(size)
}

/**
 * @param props.size - small, default or large
 * @param props.isLightTheme - 'true' if a light map is chosen
 * @param props.active - 'true' if this element is currently selected
 */
export const StyledItem = styled.li<{
  size: MapStyleSize
  isLightTheme?: boolean
  active: boolean
  isGrid: boolean
}>(({ theme, size, isLightTheme, active, isGrid }) => {
  const activeBorderColor = isLightTheme ? theme.primary : '#1791EA'
  const hoverBorderColor = isLightTheme ? theme.primary : '#438FE3'
  const borderRadius = '5px'
  const isSmall = size === MAP_STYLE_SIZES.small
  const borderSize = !isSmall ? '2px' : '1px'

  const itemSize = getItemSize(size)

  const hoverOverlayStyles = css`
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: ${borderRadius};
      background-color: ${isLightTheme ? `${theme.primary}81` : '#0A578E81'};
      border: ${borderSize} solid transparent;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &:hover::before {
      opacity: 1;
    }
  `

  return css`
    display: inline-block;
    width: ${itemSize}px;
    height: ${isSmall ? '100%' : `${itemSize}px`};
    position: ${isGrid ? 'relative' : 'inherit'};
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      border: ${borderSize} solid ${active ? activeBorderColor : 'transparent'};
      border-radius: ${!isSmall ? borderRadius : '0px'};
      transition: ${!isSmall ? 'border-color 0.2s ease' : ''};
    }

    &:hover {
      img {
        ${!isSmall
          ? // Change the border color in the 'large' or 'default' variant
            css`
              border-color: ${hoverBorderColor};
            `
          : !active
          ? // If not active, hide the border so an image will 'grow'
            css`
              border: none;
            `
          : ''}
      }
    }

    ${!isSmall ? hoverOverlayStyles : ''}
  `
})

export const StyledIcon = styled.span<{
  isLightTheme?: boolean
}>`
  color: #fff;
  background-color: ${({ isLightTheme, theme }) =>
    isLightTheme ? theme.primary : '#2699FB'};
  border-radius: 20px;
  padding: 2px;
  position: absolute;
  top: 35%;
  left: 35%;
  line-height: 16px;
`

export const ExpandButton = styled(Button)<{ isLightTheme?: boolean }>`
  ${({ isLightTheme, theme }) =>
    isLightTheme
      ? ''
      : // Override btn's colors for the dark theme
        css`
          color: ${theme['secondary-light-500']};

          &:hover {
            color: ${theme['secondary-light-700']};
          }
        `}
`
