// libraries
import { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled/macro'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useToggle, useClickAway } from 'react-use'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { TextInput, IconButton, Tooltip } from 'components/common'

// utils
import { stopEventDefaultAndPropagation } from 'helpers/utils'

const StyledTextEditor = styled.div`
  font-size: ${props => (props.field === 'textarea' ? '14px' : '20px')};
  min-height: 21px;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px 8px;
  margin-left: -8px;
  margin-bottom: ${props => (props.field === 'textarea' ? '8px' : '0px')};
  &:hover {
    color: ${props => props.theme.primary};
    background-color: ${props => props.theme['secondary-light-500']};
  }
`

const StyledEditButton = styled.div`
  width: 30px;
  text-align: center;
  background-color: transparent;
  button {
    color: ${props => props.theme.primary};
  }
`

const IssueTextEditor = ({
  value,
  field,
  onChange,
  placeholder,
  className,
  disabled,
}) => {
  const ref = useRef()
  const [isEditing, toggleIsEditing] = useToggle(false)
  const [isHovered, toggleIsHovered] = useToggle(false)

  const [textValue, setTextValue] = useState(value)

  useEffect(() => {
    setTextValue(value)
  }, [value])

  useClickAway(ref, () => {
    if (isEditing) {
      toggleIsEditing(false)
      toggleIsHovered(false)
    }
  })

  return (
    <>
      {disabled ? (
        <span>{textValue || placeholder}</span>
      ) : isEditing ? (
        <div ref={ref} className='mt-1 d-flex justify-content-between'>
          <TextInput
            value={textValue}
            onChange={val => {
              setTextValue(val)
              onChange(val)
              toggleIsEditing(false)
              toggleIsHovered(false)
            }}
            field={field}
            className={className}
            disabled={disabled}
            clickAway
          />
        </div>
      ) : (
        <StyledTextEditor
          className='d-inline-flex'
          field={field}
          onMouseEnter={() => toggleIsHovered(true)}
          onMouseLeave={() => toggleIsHovered(false)}
        >
          <div
            onClick={e => {
              toggleIsEditing(true)
              stopEventDefaultAndPropagation(e)
            }}
          >
            {textValue || placeholder}
          </div>

          <StyledEditButton>
            {isHovered && !isEditing && (
              <Tooltip
                placement={TOOLTIP_PLACEMENT.top}
                trigger={['hover']}
                overlay={<span>Edit</span>}
              >
                <IconButton
                  icon='MdModeEditOutline'
                  className='p-0'
                  onClick={e => {
                    toggleIsEditing(true)
                    stopEventDefaultAndPropagation(e)
                  }}
                  size={16}
                />
              </Tooltip>
            )}
          </StyledEditButton>
        </StyledTextEditor>
      )}
    </>
  )
}

IssueTextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  field: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

IssueTextEditor.defaultProps = {
  value: '',
  onChange: _.noop,
  field: 'input',
  placeholder: '',
  className: undefined,
  disabled: false,
}

export default IssueTextEditor
