// libraries
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { TreeTable as ReactTreeTable } from 'primereact/treetable'
import { Column } from 'primereact/column'
import type { TreeNode } from 'primereact/treenode'

// constants
import { THEMES } from 'constants/colour'

// components
import EmptyColumnsList from 'components/common/List/EmptyColumnsList'

// utils
import { noopSort, sortFunction } from 'components/common/DataTable'

import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import { StyledTreeTable } from './styles'

import type { TreeTableComponentProps } from './types'

export type { TreeTableComponentProps }

const TreeTable = ({
  list = [],
  columns = [],
  allAvailableColumns,
  setVisibleColumns,
  sortField,
  sortOrder,
  backendSortEnabled = false,
  primaryFields,
  itemActions = {},
  theme = THEMES.light,
  dataKey = 'id',
  setListConditions,
  currentActiveItem,
  rowClassName,
  scrollable = true,
  scrollHeight = '100%',
  emptyMessage = 'No items',
  loading,
  resizableColumns = true,
  className = 'position-relative w-100 h-100',
}: TreeTableComponentProps): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])
  const tableRef = useRef<ReactTreeTable | null>(null)

  const renderColumns = useCallback(() => {
    return _.map(_.compact(columns), col => {
      const {
        header,
        field,
        bodyStyle,
        style,
        key,
        sortField: colSortField,
        body,
      } = col
      const columnKey = field ?? key ?? header
      return (
        <Column
          key={`${columnKey}-${header}`}
          {..._.omit(col, 'key')}
          {...{
            sortField: colSortField || field,
            sortFunction: backendSortEnabled ? noopSort : sortFunction,
          }}
          body={body || (rowData => (field ? _.get(rowData, field) : null))}
          bodyStyle={
            _.includes(primaryFields, header)
              ? { fontWeight: 'bold' }
              : bodyStyle
          }
          style={style}
        />
      )
    })
  }, [columns, backendSortEnabled, primaryFields])

  const isColumnsListEmpty = useMemo(
    () =>
      _.isEmpty(columns) ||
      (_.size(columns) === 1 && !_.get(columns, '[0].field')),
    [columns]
  )

  const [selectionKeys, setSelectionKeys] = useState<string | null>(null)

  useEffect(() => {
    setSelectionKeys(currentActiveItem ? currentActiveItem.id : null)
  }, [currentActiveItem])

  const treeNodeClassName = useMemo(
    () => (node: TreeNode) => ({
      'parent-row': !!node.children,
      'child-row': !node.children,
      ...(rowClassName ? rowClassName(node) : {}),
    }),
    [rowClassName]
  )

  const selectionProps = useMemo(() => {
    return currentActiveItem
      ? {
          selectionMode: 'single',
          selectionKeys,
          onSelectionChange: (event: { value: string | null }) =>
            setSelectionKeys(event.value),
        }
      : {}
  }, [currentActiveItem, selectionKeys])

  if (isColumnsListEmpty && allAvailableColumns && setVisibleColumns) {
    return (
      <EmptyColumnsList
        allAvailableColumns={allAvailableColumns}
        setVisibleColumns={setVisibleColumns}
      />
    )
  }

  return (
    <StyledTreeTable isLightTheme={isLightTheme} className={className}>
      <ReactTreeTable
        ref={tableRef}
        value={list}
        sortField={sortField}
        scrollable={scrollable}
        sortOrder={sortOrder}
        scrollHeight={scrollHeight}
        key={dataKey}
        emptyMessage={emptyMessage}
        resizableColumns={resizableColumns}
        showGridlines
        loading={loading}
        rowClassName={treeNodeClassName}
        {...itemActions}
        {...selectionProps}
        {...(setListConditions && {
          // The 'onSort' prop switches a sorting to a 'controlled' mode
          onSort: eventData => {
            // Save the selected sorting options
            setListConditions(prevState => ({
              ...prevState,
              sortField: eventData.sortField,
              ascOrder: eventData.sortOrder === 1,
            }))
          },
        })}
      >
        {renderColumns()}
      </ReactTreeTable>
    </StyledTreeTable>
  )
}

export default TreeTable
