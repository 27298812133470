import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

export const StyledLayersList = styled.div<{ isLightTheme: boolean }>(
  ({ isLightTheme }: { isLightTheme: boolean }) => css`
    background-color: ${isLightTheme ? '#FFFFFF' : '#031C2E'};
  `
)

export const StyledSection = styled.div<{
  isLightTheme: boolean
  isVisible?: boolean
}>`
  padding: 9px 22px;
  font-size: 13px;
  color: #141414;
  color: ${props => (props.isLightTheme ? '#141414' : '#fff')};
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.isLightTheme ? props.theme['primary-100'] : '#0D4F7E'};
    color: ${props => (props.isLightTheme ? props.theme.primary : '#fff')};

    .svgButton {
      opacity: 1;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .svgButton {
    opacity: 0;
  }
`
