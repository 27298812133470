import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { HEADING_SIZES } from 'constants/formBuilder'
import { HeadingSizes } from 'types/formBuilder'

const HEADING_STYLES_BY_SIZE = {
  [HEADING_SIZES.large]: css`
    font-size: 24px;
  `,
  [HEADING_SIZES.medium]: css`
    font-size: 20px;
  `,
  [HEADING_SIZES.small]: css`
    font-size: 16px;
  `,
}

const SUBHEADING_STYLES_BY_SIZE = {
  [HEADING_SIZES.large]: css`
    font-size: 16px;
  `,
  [HEADING_SIZES.medium]: css`
    font-size: 14px;
  `,
  [HEADING_SIZES.small]: css`
    font-size: 13px;
  `,
}

const getHeadingStylesBySize = ({ size }: { size: HeadingSizes }) =>
  HEADING_STYLES_BY_SIZE[size]
const getSubHeadingStylesBySize = ({ size }: { size: HeadingSizes }) =>
  SUBHEADING_STYLES_BY_SIZE[size]

export const StyledHeading = styled.div<{ size: HeadingSizes }>`
  color: '#000000';
  ${getHeadingStylesBySize}
`

export const StyledSubHeading = styled.div<{ size: HeadingSizes }>`
  color: #7a7a7a;
  margin-top: 4px;
  ${getSubHeadingStylesBySize};
`
