import { memo, useMemo, ReactElement } from 'react'
import { useTable, Column, Row } from 'react-table'

// constants
import { THEMES } from 'constants/colour'

import type { Payload, ThemeType } from 'types/common'

import scss from './index.module.scss'

export type DataItem = Payload

type TableProps = {
  columns: Column<DataItem>[]
  data: DataItem[]
  onSelectedRow?: (row: Row) => void
  theme?: ThemeType
  withHeader: boolean
  tableStyle?: string
  withTableHover?: boolean
}

const Table = ({
  columns,
  data,
  onSelectedRow,
  theme,
  withHeader = true,
  tableStyle = 'striped',
  withTableHover = true,
}: TableProps): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <div className='table-responsive'>
      <table
        {...getTableProps()}
        className={`table table-${tableStyle} bg-transparent ${
          !isLightTheme && 'table-dark-blue'
        } ${withTableHover ? 'table-hover' : ''} `}
      >
        {withHeader && (
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
        )}

        <tbody>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                {...(onSelectedRow && {
                  onClick: () => onSelectedRow(row),
                  className: scss.row,
                })}
              >
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      {...(cell.column.width && { width: cell.column.width })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

Table.defaultProps = {
  onSelectedRow: undefined,
  theme: THEMES.dark,
}

export default memo(Table)
