// libraries
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import isEqual from 'fast-deep-equal'
import { useGetSet, useUnmount, useUpdateEffect } from 'react-use'
import { useRequest } from 'ahooks'

// constants
import { FILTER_CONDITIONS } from 'constants/filter'
import { GALLERY_LIST_FILTER_TYPES } from 'constants/common'
import { LOAD_MORE_ITEMS_PER_PAGE } from 'app/MissionControlMethaneSolution/constants/common'

// utils
import {
  fetchListData,
  updateList,
  objectToBase64,
  fetchGraphQLListData,
} from 'helpers/utils'
import log, { reportErrors } from 'helpers/log'

import type { Filters } from 'types/filter'
import type { PageInfo } from 'types/graphql'
import type {
  ActionType,
  AsyncState,
  Payload,
  SortByOptions,
} from 'types/common'
import type { RelayStyleData, ListRelayStyleData } from 'services/api/utils'
import type { QueryParams } from 'types/services'
import type { OnListItemChange } from './useListItemActions'

const encodeFilters = (filters: Filters, condition: string) =>
  objectToBase64(
    condition === FILTER_CONDITIONS.or ? { $or: filters } : filters
  )

export const getListFiltersParams = (
  filters: Filters,
  condition: string,
  isGraphql = false
): Payload => {
  const validFilters = _.omitBy(filters, filter =>
    _.isArray(filter) ? _.isEmpty(filter) : _.isNil(filter)
  )

  return _.isEmpty(validFilters)
    ? {}
    : {
        filter: isGraphql
          ? validFilters
          : encodeFilters(validFilters, condition),
      }
}

// https://ahooks.js.org/hooks/use-request/basic#options
const DEFAULT_USE_REQUEST_OPTIONS = {
  manual: true,
}

type GraphqlData<T = unknown> = { data: T[]; pageInfo?: PageInfo }

export type UseFetchListProps<T = unknown> = Partial<{
  key?: string
  initialList: T[]
  listFn: (payload: ListRelayStyleData<T>) => Promise<RelayStyleData<T>>
  updateListFn: (list: T[]) => void
  shouldFetchOnMount: boolean
  filters: Filters
  setFilterValues?: React.Dispatch<React.SetStateAction<Filters>>
  isGraphql: boolean
  first: number
  filtersCondition: string
  delayInSeconds: number
  listFnParams?: QueryParams
  sortBy?: SortByOptions
  queryPayload?: Record<string, unknown>
  cacheKey?: string
}>

export type UseFetchListState<T = unknown> = {
  list: T[]
  pageInfo: PageInfo
  listState: AsyncState<T[]> & { isFirstRequestDone?: boolean }
  abortController?: AbortController
  onChange: OnListItemChange<T>
  setList: React.Dispatch<React.SetStateAction<T[]>>
  fetchMoreListData: () => Promise<void>
  fetchAllListData: () => Promise<void>
  fetchList: (
    enableLoadMore?: boolean,
    loadMoreFirst?: number
  ) => Promise<GraphqlData<T> | T[]>
  isLoadingMore: boolean
}
const MAX_ATTEMPTS_TO_FETCH_MORE_DATA = 100

const useFetchList = <T>({
  key,
  listFn,
  updateListFn = _.noop,
  initialList,
  filters,
  filtersCondition = FILTER_CONDITIONS.and,
  isGraphql = true,
  delayInSeconds = 0,
  first,
  listFnParams,
  sortBy,
  queryPayload, // anything else you want to pass into a query
  cacheKey,
}: UseFetchListProps<T>): UseFetchListState<T> => {
  const isFirstRequestDoneRef = useRef(false)

  const queryParams = useMemo(() => {
    if (!filters) return undefined

    const backendFilters = _.omit(filters, [
      GALLERY_LIST_FILTER_TYPES.isFavorite,
    ])
    return _.omitBy(
      {
        ...getListFiltersParams(backendFilters, filtersCondition, isGraphql),
        sortBy,
      },
      _.isNil
    )
  }, [filters, filtersCondition, isGraphql, sortBy])

  const [list, setList] = useState(() => initialList ?? [])

  const [getPageInfo, setPageInfo] = useGetSet<PageInfo | undefined>(
    {} as PageInfo
  )

  const [abortController, setAbortController] = useState<AbortController>()

  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const useRequestOptions = useMemo(() => {
    return {
      ...DEFAULT_USE_REQUEST_OPTIONS,
      ...(cacheKey && { cacheKey }),
    }
  }, [cacheKey])

  const {
    // https://ahooks.js.org/hooks/use-request/basic#result
    runAsync: fetchList,
    loading,
    error,
    data,
  } = useRequest(
    async (
      enableLoadMore = false,
      loadMoreFirst?: number
    ): Promise<GraphqlData | T[]> => {
      if (!_.isFunction(listFn)) {
        reportErrors('listFn is invalid', { listFn })
        return { data: [] }
      }

      if (!enableLoadMore) {
        setPageInfo({} as PageInfo)
        if (delayInSeconds) {
          await new Promise(resolve => {
            setTimeout(resolve, delayInSeconds * 1000)
          })
        }
      }

      const newAbortController = abortController ?? new AbortController()
      if (!abortController) {
        setAbortController(newAbortController)
      }

      const fetchData = async (shouldLoadNextPage?: boolean) => {
        const pageInfo = getPageInfo()
        const result = isGraphql
          ? await fetchGraphQLListData({
              ...listFnParams,
              fetchFunc: listFn,
              queryParams: {
                ...queryParams,
                ...(first && { first }),
                ...(shouldLoadNextPage && {
                  ...(pageInfo?.endCursor && { after: pageInfo.endCursor }),
                  first: loadMoreFirst ?? first,
                }),
                ...queryPayload,
              },
              abortController: newAbortController,
            })
          : await fetchListData(listFn, { ...queryParams, ...listFnParams })

        if (isGraphql) {
          const { data: resultData, pageInfo: newPageInfo } = (result ??
            {}) as GraphqlData<T>
          setPageInfo(newPageInfo)
          setList(oldList =>
            shouldLoadNextPage ? [...oldList, ...resultData] : resultData
          )
        } else {
          setList(result as [])
        }

        return result
      }

      let attempts = 0
      let newList = []
      let hasNextPage
      let endCursor
      let result
      let hasNoValidDataButHasNextPageAndEndCursor
      do {
        result = (await fetchData(
          hasNoValidDataButHasNextPageAndEndCursor || enableLoadMore
        )) as GraphqlData<T>

        newList = result?.data
        hasNextPage = result?.pageInfo?.hasNextPage
        endCursor = result?.pageInfo?.endCursor
        hasNoValidDataButHasNextPageAndEndCursor = !!(
          _.isEmpty(newList) &&
          hasNextPage &&
          endCursor
        )
        attempts += 1

        if (attempts >= MAX_ATTEMPTS_TO_FETCH_MORE_DATA) {
          if (hasNoValidDataButHasNextPageAndEndCursor) {
            reportErrors(
              `Attempted ${attempts} times to fetch data, but still haven't received valid data and the next page is still available.`,
              { listFnParams, queryParams }
            )
          }
          break
        }

        if (hasNoValidDataButHasNextPageAndEndCursor) {
          log.info(
            `No valid data received and the next page is available. Attempt ${
              attempts + 1
            } to fetch data.`
          )
        }
      } while (hasNoValidDataButHasNextPageAndEndCursor)

      isFirstRequestDoneRef.current = true
      setAbortController(undefined)
      return result
    },
    useRequestOptions
  )

  const onChange = useCallback(
    (type: ActionType, payload?: Partial<T>) => {
      const newList: T[] = updateList(list, type, payload, {
        insertOnTop: true,
      })
      setList(newList)
    },
    [list]
  )

  useEffect(() => {
    fetchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, queryPayload, key])

  useUpdateEffect(() => {
    if (!isEqual(initialList, list)) {
      updateListFn(list)
    }
  }, [list])

  useUpdateEffect(() => {
    if (!initialList) return

    setList(oldList => (isEqual(initialList, oldList) ? oldList : initialList))
  }, [initialList])

  const fetchMoreListData = useCallback(
    async (loadMoreFirst?: number) => {
      setIsLoadingMore(true)
      await fetchList(true, loadMoreFirst)
    },
    [fetchList]
  )

  const fetchAllListData = useCallback(
    async (loadMoreFirst = LOAD_MORE_ITEMS_PER_PAGE) => {
      let loadMore = getPageInfo()?.hasNextPage
      while (loadMore) {
        await fetchMoreListData(loadMoreFirst)
        loadMore = getPageInfo()?.hasNextPage
      }
    },
    [fetchMoreListData, getPageInfo]
  )

  useEffect(() => {
    if (!loading) {
      setIsLoadingMore(false)
    }
  }, [loading])

  useUnmount(() => {
    abortController?.abort()
  })

  return {
    list,
    setList,
    onChange,
    fetchList,
    fetchMoreListData,
    fetchAllListData,
    isLoadingMore,
    pageInfo: getPageInfo() || {},
    abortController,
    listState: {
      loading,
      error,
      value: data,
      isFirstRequestDone: isFirstRequestDoneRef.current,
    },
  }
}

export default useFetchList
