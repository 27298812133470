// libraries
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

import type { ToastContainerProps } from 'components/common/Toast/type'

// constants
import { TOAST_WIDTH } from './constants'

import {
  enterAnimation,
  getMessageContainerOutlineStyles,
  getMessageContainerStyles,
  leaveAnimation,
  MESSAGE_STYLE_CONFIG,
} from '../Message/styles'

export const ToastContainer = styled.div<ToastContainerProps>`
  width: ${TOAST_WIDTH}px;
  border-radius: ${MESSAGE_STYLE_CONFIG.borderRadius};
  position: relative;
  background: #fff;
  box-shadow: ${MESSAGE_STYLE_CONFIG.boxShadow};
  padding: 8px 0;

  animation: ${({ visible }) =>
    visible
      ? css`
          ${enterAnimation} ${MESSAGE_STYLE_CONFIG.animationDurationEnter} ease-out
        `
      : css`
          ${leaveAnimation} ${MESSAGE_STYLE_CONFIG.animationDurationLeave} ease-in forwards
        `};

  ${({ type, outline, theme }) =>
    outline
      ? getMessageContainerOutlineStyles(theme)[type]
      : getMessageContainerStyles(theme)[type]};
`
