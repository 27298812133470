import { selectorFamily } from 'recoil'
import _ from 'lodash'
import { getSiteEquipments } from '../services/api/detection'

export const siteEquipmentsQuery = selectorFamily({
  key: 'SiteEquipments',
  get:
    ({
      parentAssetReference,
      group,
    }: {
      parentAssetReference?: string
      group?: string | Array<string>
    }) =>
    async () => {
      if (!parentAssetReference) return []

      const response = await getSiteEquipments({
        queryParams: {
          filter: {
            ...(group && { group }),
            propertyFilters: [
              {
                property: 'isAttributable',
                valueJson: true,
              },
            ],
            parentAssetReference,
          },
        },
      })

      return _(response.data)
        .sortBy('description')
        .map(
          ({ assetReference, properties, equipmentComponents, ...rest }) => ({
            ...rest,
            properties,
            value: assetReference,
            label: properties.name,
            components: _.map(equipmentComponents, component => {
              return {
                equipmentComponentsName: component.name,
              }
            }),
            labelExtras: {
              description: properties.FLOC,
            },
          })
        )
        .value()
    },
})
