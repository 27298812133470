// libraries
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

export const StyledInput = styled.input<{ hideDefaultControls?: boolean }>`
  cursor: ${props => (props.readOnly ? 'default' : 'text')};
  &:active,
  &:focus {
    border-color: ${props => (props.readOnly ? '#ddd' : props.theme.primary)};
  }

  ${({ hideDefaultControls }) =>
    hideDefaultControls
      ? css`
          /** Hiding arrows */
          appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            appearance: none;
          }
        `
      : ''}
`
