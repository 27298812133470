import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

export const StyledValue = styled.span<{
  highlightPlaceholder?: boolean
  isPlaceholder?: boolean
}>`
  ${({ isPlaceholder, highlightPlaceholder, theme }) =>
    isPlaceholder
      ? css`
          color: ${highlightPlaceholder
            ? theme.primary
            : theme['secondary-500']};
        `
      : ''};
`
