// libraries
import styled from '@emotion/styled/macro'

export const StyledMapStyleControl = styled.div<{
  x?: number
  y?: number
}>`
  ${props => props.x && `left: ${props.x}px;`}
  ${props => props.y && `top: ${props.y}px;`}
`
