import { ReactElement, ReactNode } from 'react'
import styled from '@emotion/styled/macro'

import scss from './index.module.scss'

export const StyledPaper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`

const Paper = ({
  width,
  className = '',
  children,
}: {
  width: string
  className?: string
  children?: ReactNode
}): ReactElement => (
  <StyledPaper className={`${scss.paper} ${className}`} width={width}>
    {children}
  </StyledPaper>
)

export default Paper
