// libraries
import { ReactElement } from 'react'
import _ from 'lodash'
import styled from '@emotion/styled/macro'

// constants
import { BADGE_TYPES } from 'constants/common'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// utils
import { stopEventDefaultAndPropagation } from 'helpers/utils'
import type { CardActions } from 'components/common/List/Card/CardOptions'
import type { FormList } from 'types/formBuilder'

// components
import { Badge, CardOptions, Star } from 'components/common'
import * as Icons from 'components/icons'

import scss from './index.module.scss'

type FormListRowProps = {
  isActive: boolean
}

const StyledFormListRow = styled.li<FormListRowProps>`
  background-color: ${props =>
    props.isActive ? props.theme['secondary-light-500'] : '#fff'};
  &:hover {
    background-color: ${props => props.theme['secondary-light-500']};
  }
`

const FormListItem = ({
  isActive,
  item,
  itemActions,
  setSelectedFormListId,
}: {
  isActive: boolean
  item: FormList
  itemActions: CardActions<FormList>
  setSelectedFormListId: (v: string) => void
}): ReactElement => {
  const { id, name, isFavorite } = item
  const { onFavorite, ...restItemActions } = itemActions

  return (
    <StyledFormListRow
      className={scss.row}
      isActive={isActive}
      onClick={e => {
        setSelectedFormListId(id)
        stopEventDefaultAndPropagation(e)
      }}
    >
      <div className={scss.name}>
        <Icons.AiOutlineUnorderedList className='me-2' size={14} />
        {name}
      </div>
      <div className={scss.status}>
        <div className={scss.badge}>
          <Badge content='static' type={BADGE_TYPES.infoGrey} />
        </div>

        <Star
          size={18}
          isActive={isFavorite}
          onChange={e => onFavorite?.(item)}
        />

        {!_.isEmpty(restItemActions) && (
          <CardOptions
            {...restItemActions}
            subject={item}
            className='actionMenu'
            placement={TOOLTIP_PLACEMENT.bottomRight}
            enableFavorite
          />
        )}
      </div>
    </StyledFormListRow>
  )
}

export default FormListItem
